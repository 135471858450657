import tw, { css, styled } from 'twin.macro';
import { CustomCssProps } from '~/config';

/**
 * 9-slicing prevents corners from deforming at any size (https://developer.mozilla.org/en-US/docs/Web/CSS/border-image-slice)
 *
 * To recreate this for another project:
 *
 * - For the one here I laid out a special version of the bg in Figma that's an even tic-tac-toe 3x3 grid, to keep the css simple,
 *   but `border-width` and `border-image-slice` can have different width and heights for the slices - check the doc link above.
 *
 * - I ran the SVG through SVGOMG to reduce size then pasted it here, where it's converted to an image. The fill and stroke were
 *   set to white and black in the artwork to make it easy to replace the colors here with JS, or you could do that with CSS.
 *
 * - A minor gotcha is that the button content needed a z-index to be drawn above this image.
 */
export const NineSliceBg = styled.div<
  CustomCssProps & {
    bgColor: string;
    borderColor: string;
  }
>(({ bgColor, borderColor, customCss }) => {
  if (!bgColor) {
    bgColor = '#38373C';
  }

  const bgImageEncoded = encodeURIComponent(
    `<svg width="78" height="78" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M76.04 58.35c.27-.72.45-1.43.52-2.27v-1.09c.08-1.07.3-2.22.44-3.32V26.34c0-3.04-.58-8.46-1.73-10.75-.9-1.77-1.48-2.16-2.9-3.58-2.44-2.74-4.36-5.84-8.71-7.66-3.06-1.93-6.42-3.25-9.97-3.34-.67-.02-1.29 0-2.02 0H26.34c-2.84.53-6.29.24-9.3 1.73l-.1.04c-.87.38-1.72.81-2.57 1.3-.7.35-1.27 1.74-1.98 2.1-1.78.7-3.52.9-4.4 2.48-.54.7-.9 1.59-1.43 2.12-1.42 1.94-3.02 3.53-3.69 5.78l-.07.18a24.06 24.06 0 0 0-1.72 8.73v.2L1 26.3V51.67a24.5 24.5 0 0 0 .8 5.84c.88 5.58 4.79 9.82 8.98 13.41l.15.14c3.32 2.88 7.25 5.01 11.88 5.17l.2.05c.57.12 2.37.58 3.53.72l25.12-.01h2.02c4.7-.15 9.01-1.65 12.63-4.45l.16-.13c1.31-1.2 1.47-2.75 2.72-3.81 4.36-2.75 5.6-6.27 6.78-10.06l.06-.19Z" fill="#fff"/><path d="M26.34 1c-2.84.54-6.29.25-9.3 1.74l-.1.04c-.87.38-1.72.81-2.57 1.3-.7.35-1.27 1.74-1.98 2.1-1.78.7-3.52.9-4.4 2.48-.54.7-.9 1.59-1.43 2.12-1.42 1.94-3.02 3.53-3.69 5.78l-.07.18a24.06 24.06 0 0 0-1.72 8.73v.2l-.08.67M26.34 1h25.33M26.34 1h25.33m0 0c.73 0 1.35-.02 2.02 0 3.55.09 6.91 1.4 9.97 3.34 4.35 1.82 6.27 4.92 8.7 7.66 1.43 1.42 2.02 1.81 2.9 3.58 1.16 2.29 1.74 7.7 1.74 10.75m0 0v25.33m0-25.33v25.33m0 0c-.13 1.1-.36 2.25-.44 3.32v1.09a8.29 8.29 0 0 1-.52 2.27l-.06.2c-1.18 3.78-2.42 7.3-6.78 10.05-1.25 1.06-1.4 2.61-2.72 3.81l-.16.13a21.38 21.38 0 0 1-12.63 4.45h-2.02L26.55 77c-1.16-.14-2.96-.6-3.53-.72l-.2-.05c-4.63-.16-8.56-2.3-11.88-5.17l-.15-.14c-4.2-3.6-8.1-7.83-8.97-13.4A24.5 24.5 0 0 1 1 51.67m0-25.34v25.33m0-25.33v25.33" stroke="#45413c" stroke-width=".5" stroke-miterlimit="10"/></svg>`
      .replace('#fff', bgColor)
      .replace('#45413c', borderColor),
  );
  return [
    css`
      border-image: url('data:image/svg+xml,${bgImageEncoded}');
    `,
    tw`absolute inset-0
    // border-width is 1/3rd the dimensions of the svg, which is set up as a square, see notes
    [border-width: 26px] [border-image-slice: 33.33% fill]
    `,
    customCss,
  ];
});
