import { AnimatePresence, useAnimationFrame } from 'framer-motion';
import * as PIXI from 'pixi.js';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { DayContext } from '~/context';
import { useResize } from '~/hooks';
import { EmojiSprite } from './EmojiSprite';

// Patch for Netlify builds
import '@pixi/unsafe-eval';

type PixiReactionsProps = {
  children?: React.ReactNode;
};

// Context was probably overkill but
export const PixiContext = createContext<{
  app: PIXI.IRenderer<PIXI.ICanvas> | undefined;
  container: PIXI.Container<PIXI.DisplayObject> | undefined;
}>({
  app: undefined,
  container: undefined,
});

/**
 *
 */
export const PixiReactions: FC<PixiReactionsProps> = ({ children }) => {
  const [hoveredReaction, setHoveredReaction] = useState(null);
  const pixiRef = useRef<HTMLDivElement>();

  const [app, setApp] = useState<PIXI.IRenderer<PIXI.ICanvas>>();
  const [container, setContainer] =
    useState<PIXI.Container<PIXI.DisplayObject>>();

  const [ready, setReady] = useState(false);
  const { reactions, requestByPath } = useContext(DayContext);

  // Render loop
  const animate = useCallback(() => {
    if (container && app) {
      app.render(container);
    }
  }, [app, container]);

  // Setup function for PIXI
  const setRef = useCallback((ref) => {
    pixiRef.current = ref;

    const renderer = PIXI.autoDetectRenderer({
      backgroundAlpha: 0,
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: true,
    });

    const stage = new PIXI.Container();

    setApp(renderer);
    setContainer(stage);

    ref.appendChild(renderer.view);

    setReady(true);
  }, []);

  // Render loop in framer motion to avoid excessive RAFs
  useAnimationFrame(animate);

  const handleResize = useCallback(
    (data) => {
      if (app && app.view.style) {
        const { viewportHeight, viewportWidth } = data;
        app.view.style.height = viewportHeight + 'px';
        app.view.style.width = viewportWidth + 'px';

        app.resize(viewportWidth, viewportHeight);
      }
    },
    [app],
  );

  useResize(handleResize);

  const handleHover = useCallback((reaction) => {
    setHoveredReaction(reaction);
  }, []);

  return (
    <div tw="fixed inset-0" ref={setRef}>
      <PixiContext.Provider value={{ app, container }}>
        <AnimatePresence>
          {ready &&
            reactions
              .filter(
                // validate cms data in case this is a Custom reaction for a day without one
                (v) => !!v.emoji,
              )
              .map((v, index) => (
                <EmojiSprite
                  key={`${index}-${v['_id']}`}
                  {...v}
                  emoji={v.emoji}
                  index={index}
                  requestByPath={!!v.emoji.liveReactionPath && requestByPath}
                  hovered={hoveredReaction === `${index}-${v['_id']}`}
                  handleHover={handleHover}
                />
              ))}
        </AnimatePresence>
        {children}
      </PixiContext.Provider>
    </div>
  );
};
