import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

/** Get the path corrosponding to the given date */
export const toPath = (date: string | Date) => {
  if (typeof date === 'string') {
    return `/day/${date}/`;
  } else {
    return `/day/${dayjs(date).format('YYYY-MM-DD')}/`;
  }
};

// Adds ordinal formatting support
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Convert a date to UTC-0
 * @param date Date object or string
 * @returns Date object adjusted to UTC-0
 */
export const utc = (date: Date | string) => {
  const d = date instanceof Date ? date : new Date(date);
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000);
};

/**
 * Format a kebab case ("year-month-day") string and a tense into a colloquial string
 * e.g. 'today is', '11.27 is', '2.27 was'
 * @param dayPath the path of the date in question: /day/12-25-2022/
 * @param tense tense of date, either past (-1), present (0) or future (1)
 * @returns Formatted string
 */
export const dateFormatTense = (dayPath: string, tense: -1 | 0 | 1) => {
  const d = dayjs(dayPath.replace('/day/', '').replace('/', ''));

  const prefix = tense !== 0 ? d.format('MM.DD') : 'today';
  const suffix = tense <= -1 ? 'was' : 'is';
  return `${prefix} ${suffix}`;
};

/**
 * Format reaction date for use in Reaction bubble content
 * @param date Date object or string
 * @returns Formatted string of colloquial time since date
 */
export const dateFormatReaction = (date: Date | string) => {
  const d = dayjs(date);

  let tense = d.format('MMM Do');
  if (d.isToday()) {
    tense = 'today';
  } else if (d.isYesterday()) {
    tense = 'yesterday';
  }

  return `${tense} at ${d.format('LT')}`;
};

/**
 * Get the current year according to the client time
 * @returns The current year
 */
export const currentYear = () => new Date().getFullYear();

/**
 * Fetch the total number of days in a given month
 * @param month Zero-indexed month index (e.g. 0 = Jan, 1 = Feb, 2 = Mar, 11 = Dec)
 * @param year Year, will return 29 days in Feb for leap years if defined and defaults to current year
 * @returns Total number of days in the requested month
 */
export const daysInMonth = (month: number, year?: number) => {
  // Use current year if not specified
  year = year || currentYear();

  return new Date(
    year,
    // Add one to maintain zero-index of months
    month + 1,
    // By passing 0 for the day, we get the last day of the previous month
    0,
  ).getDate();
};

/**
 * Accepts a string in 'YYYY-MM-DD' format, and returns a simplified date
 * string with ordinal, e.g., 'January 15th, 2024`
 */
export const convertDate = (date: string) => {
  return dayjs(date).format('MMMM Do, YYYY');
};
