import {
  animate,
  motion,
  useAnimationFrame,
  useMotionValue,
  usePresence,
  useSpring,
} from 'framer-motion';
import { Sprite, Text } from 'pixi.js';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import tw from 'twin.macro';
import { useResize } from '~/hooks';
import { clamp, distance } from '~/utils';
import { PixiContext } from './PixiReactions';
import {
  CENTER_MASS,
  CENTER_POINT,
  FLOAT_SPEED,
  GRAVITATIONAL_CONSTANT_DESKTOP,
  GRAVITATIONAL_CONSTANT_MOBILE,
  GRAVITATIONAL_RADIUS_DESKTOP,
  GRAVITATIONAL_RADIUS_MOBILE,
  PARTICLE_MASS,
  ReactionProps,
  SCREEN_PADDING,
} from './Reaction';

type EmojiSpriteProps = ReactionProps & {
  hovered: boolean;
  handleHover: (string) => void;
};

const SPRITE_SIZE = 38;

const calcedSize = SPRITE_SIZE + (isMobile ? 0.8 : 1);
const emojiFontSize = 72; // looked bug on mobile emulator but not on iPhone..

/**
 *
 */
export const EmojiSprite: FC<EmojiSpriteProps> = ({
  emoji,
  animation,
  index,
  location,
  hovered,
  handleHover,
  ...props
}) => {
  const { app, container } = useContext(PixiContext);
  const spriteRef = useRef<Sprite>();

  const mousePosition = useRef({ x: 0, y: 0 });

  // Polar coordinates
  // Below makes a donut distribution
  const radius = useRef(Math.random() * 0.65 + 0.35);
  const theta = useRef(Math.random() * 2 * Math.PI);

  // Values between 0 and 1
  const position = useRef({
    x: (radius.current * Math.cos(theta.current)) / 2 + CENTER_POINT.x,
    y: (radius.current * Math.sin(theta.current)) / 2 + CENTER_POINT.y,
  });

  // These are used as values for Sprite origin positons
  const startingPositionX = useMotionValue(position.current.x);
  // 1.1 so that emojis are just off screen
  const startingPositionY = useMotionValue(1.1);

  // Transform values to add to startingPosition for Sprite float positions
  const floatPositionX = useSpring(0);
  const floatPositionY = useSpring(0);

  const toolTipPositionX = useMotionValue(0);
  const toolTipPositionY = useMotionValue(0);

  const [stopMovement, setStopMovement] = useState(false);

  const isFlipped = useMemo(() => position.current.x > 0.5, [position]);

  // Framer motion AnimatePresence hook for exit animations
  const [isPresent, safeToRemove] = usePresence();

  // Kelly code 🤷
  const live = useRef({
    time: 0,
    seed: {
      x: (0.5 + 0.5 * Math.random()) * Math.random() > 0.5 ? -1 : 1,
      y: (0.5 + 0.5 * Math.random()) * Math.random() > 0.5 ? -1 : 1,
      timeX: 0.5 + 0.5 * Math.random(),
      timeY: 0.5 + 0.5 * Math.random(),
    },
    pos: {
      x: {
        current: 0,
        velocity: 0,
      },
      y: {
        current: 0,
        velocity: 0,
      },
    },
    offset: {
      x: 0,
    },
  });

  // Sprite interaction handlers
  const handleMouseOver = (data) => {
    const { x, y } = data.client;
    mousePosition.current = { x, y };
  };
  const handleMouseEnter = useCallback(() => {
    setStopMovement(true);
    handleHover(`${index}-${props['_id']}`);
    // Wants to add props but that leads to a bunch of rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleHover, index]);
  const handleMouseLeave = useCallback(() => {
    setStopMovement(false);
    handleHover('');
  }, [handleHover]);

  // Set initial values for PIXI on initial render
  useEffect(() => {
    if (app && container) {
      let sprite: Sprite;
      if (emoji.imgSrc) {
        // Doubling the size of the sprite led to better aliasing
        sprite = Sprite.from(emoji.imgSrc + '?h=76');

        sprite.height = calcedSize;
        sprite.width = calcedSize;
      } else {
        sprite = new Text(emoji.textEmoji, {
          fontFamily: 'Arial',
          fontSize: emojiFontSize,
          fill: 0xff1010,
          align: 'center',
        });
        // Need to match scaling of image sprites
        sprite.scale.set(0.5);
      }
      // Set the anchor in the center of our sprite
      sprite.anchor.x = 0.5;
      sprite.anchor.y = 0.5;

      // Position our sprite in the center of the renderer
      sprite.position.x = app.width * startingPositionX.get();
      sprite.position.y = app.height * startingPositionY.get();

      // Add the sprite to the stage
      container.addChild(sprite);

      sprite.interactive = true;

      // Attach handlers
      if (!isMobile) {
        sprite.onmouseover = handleMouseOver;
        sprite.onmouseenter = handleMouseEnter;
        sprite.onmouseleave = handleMouseLeave;
      }

      spriteRef.current = sprite;

      return () => {
        // Sometimes, for some reason the on cleanup, some sprites linger
        // These should be cleaned up by setting "safeToRemove" in the exit onCompletes
        // ...but they aren't.
        if (sprite.alpha > 0) {
          if (typeof safeToRemove === 'function') {
            safeToRemove();
          } else {
            sprite.interactive = false;
            sprite.onmouseover = null;
            sprite.onmouseenter = null;
            sprite.onmouseleave = null;

            container.removeChild(sprite);

            sprite.destroy();
          }
        }
      };
    }
    // We don't want safeToRemove in here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    app,
    container,
    emoji,
    handleMouseEnter,
    handleMouseLeave,
    startingPositionX,
    startingPositionY,
  ]);

  const dimensions = useResize();

  // Animation loop
  const onTick = (time, delta) => {
    if (!stopMovement) {
      live.current.time = time;
    }

    const { viewportWidth, viewportHeight } = dimensions.current;

    if (spriteRef.current) {
      const { x, y } = spriteRef.current.position;
      const height = 38;
      const width = 38;

      // Distance to center of screen
      const distanceValue = distance(
        x,
        0.5 * viewportWidth,
        y,
        0.5 * viewportHeight,
      );

      // stopMovement tracks state of mouse hover
      if (!stopMovement) {
        // Kelly code
        const velocityX =
          0.025 *
          live.current.seed.x *
          Math.cos(
            live.current.time *
              FLOAT_SPEED *
              live.current.seed.timeX *
              Math.PI *
              2,
          ) *
          viewportWidth;
        const velocityY =
          0.025 *
          live.current.seed.y *
          -Math.sin(
            live.current.time *
              FLOAT_SPEED *
              live.current.seed.timeY *
              Math.PI *
              2,
          ) *
          viewportHeight;

        // Refer to newton's gravitational equation for details of variables below
        const gravityConstant = isMobile
          ? GRAVITATIONAL_CONSTANT_MOBILE
          : GRAVITATIONAL_CONSTANT_DESKTOP;

        const equationResult =
          (gravityConstant * (PARTICLE_MASS * CENTER_MASS)) /
          Math.pow(distanceValue * 0.5, 2);

        // Find unit vector pointing from center to sprite location
        const unitVector = {
          x: (x - CENTER_POINT.x * viewportWidth) / distanceValue,
          y: (y - CENTER_POINT.y * viewportHeight) / distanceValue,
        };

        // Have a different gravity radius for mobile and desktop
        const gravityRadius = isMobile
          ? GRAVITATIONAL_RADIUS_MOBILE
          : GRAVITATIONAL_RADIUS_DESKTOP;

        /**
         * Apply gravity if within distance bounds otherwise sprites clump to sides
         * apply clamp so that sprites are bound to screen
         * apply screen padding so that they don't float slightly off
         */
        const xValue = clamp(
          (distanceValue > gravityRadius ? 0 : equationResult) * unitVector.x +
            velocityX +
            floatPositionX.get(),
          window.innerWidth * (-position.current.x + SCREEN_PADDING),
          window.innerWidth * (1 - position.current.x - SCREEN_PADDING) - width,
        );
        const yValue = clamp(
          (distanceValue > gravityRadius ? 0 : equationResult) * unitVector.y +
            velocityY +
            floatPositionY.get(),
          window.innerHeight * (-position.current.y + SCREEN_PADDING),
          window.innerHeight * (1 - position.current.y - SCREEN_PADDING) -
            height,
        );

        // Set values to motion values
        floatPositionX.set(xValue);
        floatPositionY.set(yValue);
      } else {
        // If mouse is hovering then stop movement and attract to mouse position
        floatPositionX.set(
          mousePosition.current.x -
            position.current.x * window.innerWidth -
            width / 2,
        );
        floatPositionY.set(
          mousePosition.current.y - position.current.y * window.innerHeight,
        );
      }
    }
  };

  // Attach on change handlers to apply sprite transforms from framer to PIXI
  useEffect(() => {
    floatPositionX.onChange((val) => {
      if (spriteRef.current && position.current) {
        toolTipPositionX.set(
          val + startingPositionX.get() * dimensions.current.viewportWidth,
        );
        spriteRef.current.position.x =
          val + startingPositionX.get() * dimensions.current.viewportWidth;
      }
    });
    floatPositionY.onChange((val) => {
      if (spriteRef.current && position.current) {
        toolTipPositionY.set(
          val + startingPositionY.get() * dimensions.current.viewportHeight,
        );
        spriteRef.current.position.y =
          val + startingPositionY.get() * dimensions.current.viewportHeight;
      }
    });

    return () => {
      floatPositionX.clearListeners();
      floatPositionY.clearListeners();
    };
  }, [
    app,
    dimensions,
    floatPositionX,
    floatPositionY,
    startingPositionX,
    startingPositionY,
    toolTipPositionX,
    toolTipPositionY,
  ]);

  // Only run on initial mount
  useEffect(() => {
    if (animation === 'swarm') {
      animate(startingPositionY, position.current.y, {
        duration: 5,
        ease: [0.165, 0.84, 0.44, 1],
        delay: ((index ? index : 1) % 15) * 0.2,
      });
    } else {
      const { x, y } = position.current;

      // Setting explosion position
      startingPositionX.set(x);
      startingPositionY.set(y);

      // Passing confetti options to event
      const confettiEvent = new CustomEvent('confetti', {
        detail: {
          origin: { x, y },
          particleCount: 150,
          spread: 360,
          startVelocity: 30,
        },
      });
      animate(1, [1, 1.5, 0.5], {
        duration: 0.65,
        ease: 'easeOut',
        onUpdate: (latest) => {
          if (spriteRef.current) {
            spriteRef.current.scale.set(latest);
          }
        },
      });
      window.dispatchEvent(confettiEvent);
    }
    // Only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only run when isPresent changes to detect when to run exit animation
  useEffect(() => {
    if (!isPresent) {
      if (spriteRef.current) {
        // Exit animations ⬇

        // Opacity
        animate(1, 0, {
          onUpdate: (latest) => {
            if (spriteRef.current && !spriteRef.current.destroyed) {
              spriteRef.current.alpha = latest;
            }
          },
          duration: 0.75,
          delay: ((index ? index : 1) % 10) * 0.1,
          onComplete: safeToRemove,
        });

        // Scale
        animate(0.5, [0.5, 0.75, 0.15], {
          duration: 0.75,
          delay: ((index ? index : 1) % 10) * 0.1,
          onUpdate: (latest) => {
            if (spriteRef.current && !spriteRef.current.destroyed) {
              spriteRef.current.scale.set(latest);
            }
          },
          // Component won't unmount unless safeToRemove is run
          onComplete: safeToRemove,
        });
      }
    }
    // IMPORTANT: Do not add suggested dependencies as this ruins exit anim
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  useAnimationFrame(onTick);

  return (
    <>
      {hovered && (
        <PositionDiv
          style={{ x: toolTipPositionX, y: toolTipPositionY }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}
          key={`index-${location}`}
        >
          <Tooltip
            tabIndex={-1}
            tw="
              relative flex flex-col whitespace-nowrap px-3 py-1.5 bg-theme-fg rounded-md
              text-left font-tertiary text-[0.6875rem] leading-[1.38] tracking-[0.05em] text-theme-base-invert
            "
            css={[
              isFlipped
                ? tw`[transform: translate(calc(-100% - 1.75rem), -50%)]`
                : tw`[transform: translate(1.75rem, -50%)]`,
            ]}
          >
            <svg
              tw="absolute top-1/2 block w-[1.3125rem] mt-[-0.25rem] fill-theme-fg"
              css={[isFlipped ? tw`left-full` : tw`right-full`]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 35.6"
              transform={`translate(${isFlipped ? -8 : 8}, 0) rotate(${
                isFlipped ? 90 : -90
              }) scale(0.85)`}
            >
              <path d="M65.5,10.95C61.4,4.02,56.77,.88,52.64,.25c-.85-.16-1.73-.25-2.63-.25-.85,0-1.68,.08-2.49,.22-4.17,.57-8.87,3.7-13.02,10.73C24.68,27.58,16.31,33.49,0,35.6H100c-16.31-2.1-24.68-8.02-34.5-24.64Z" />
            </svg>
            {emoji.liveReactionInitiative && (
              <em tw="font-light">{emoji.liveReactionInitiative}</em>
            )}
            <em tw="font-light">{location}</em>
          </Tooltip>
        </PositionDiv>
      )}
    </>
  );
};

const PositionDiv = tw(motion.div)`
  inline-block z-[50] absolute top-0 left-0`;

const Tooltip = tw.button`
flex items-center pointer-events-none
`;
