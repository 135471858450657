import { HexColor } from '@smockle/contrast';
import { createContext, FC, ReactNode, useRef } from 'react';
import tw from 'twin.macro';
import { randomTheme } from '~/utils';

export type Theme = {
  foreground: string;
  background: string;
  invertBase?: boolean;
};

export const isTheme = (t: object): t is Theme => {
  return (
    typeof t === 'object' &&
    t.hasOwnProperty('foreground') &&
    t.hasOwnProperty('background')
  );
};

export const DEFAULT_THEMES = {
  BLUE: { foreground: 'var(--blue)', background: 'var(--blue-wash)' },
  RED: { foreground: 'var(--red)', background: 'var(--red-wash)' },
  YELLOW: {
    foreground: 'var(--yellow)',
    background: 'var(--yellow-wash)',
  },
};

const DEFAULT_THEME = DEFAULT_THEMES.BLUE;

export const ThemeContext = createContext({
  theme: DEFAULT_THEME,
  setTheme: (theme?: Partial<Theme>) => {},
});

type ThemeContextProviderProps = {
  children?: ReactNode;
};

export const ThemeChangeEventName = 'themechange';

export const TwThemeTransition = tw`transition-colors duration-1000 ease-in-out`;

export const ThemeContextProvider: FC<ThemeContextProviderProps> = ({
  children,
}) => {
  const theme = useRef<Theme | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const themeSetterTimeout = useRef<NodeJS.Timeout>();

  const setTheme = (t?: Partial<Theme>) => {
    // A random theme is set upon first load (below) but only propogated via a setTimeout
    // When a page is loading that actually has a CMS-set theme, it calls this function in the interval
    // So in those cases, assume a call to setTheme has priority and stop the timeout from propogating
    if (themeSetterTimeout.current) {
      clearTimeout(themeSetterTimeout.current);
      themeSetterTimeout.current = undefined;
    }

    if (!t || !t.background) {
      updateTheme(randomTheme());
    } else if (!t.foreground) {
      const bg = new HexColor(t.background);
      const newTheme = randomTheme('AAA', 'regular', bg.toEightBitColor());
      updateTheme(newTheme);
    } else {
      // In cases where both a FG and BG color are set, assume they're legit contrast-wise
      // But we do need to check if the theme color should be inverted, based on if the BG is "dark"
      const bg = new HexColor(t.background).toEightBitColor();
      if (bg.B.value + bg.G.value + bg.R.value < (255 * 3) / 2) {
        t.invertBase = true;
      }

      updateTheme(t as Theme);
    }
  };

  /* 
    Apply theme changes directly at the root, rather than via a stateful re-render. 
    We don't want to re-render the whole tree whenever the theme changes, we want the
    CSS engine to pick up on the change to the CSS variables and apply them that way.
  */
  const updateTheme = (t: Theme) => {
    // Update theme color
    if (rootRef.current) {
      // Update theme variables
      const themeVariables = [
        ['--theme-fg', t.foreground],
        ['--theme-bg', t.background],
        ['--theme-bg-faded-heavy', `${t.background}88`], // special rgba for MobileDimmer
        ['--theme-bg-faded-light', `${t.background}C6`], // special rgba for MobileDimmer
        [
          '--theme-base',
          `var(--${t.invertBase ? 'theme-base-white' : 'theme-base-black'})`,
        ],
        [
          '--theme-base-invert',
          `var(--${t.invertBase ? 'theme-base-black' : 'theme-base-white'})`,
        ],
      ];
      for (let i = 0, len = themeVariables.length; i < len; i++) {
        rootRef.current.style.setProperty(
          themeVariables[i][0],
          themeVariables[i][1],
        );
      }
      document.body.style.backgroundColor = t.background;
      // console.log('updateTheme', t);

      const e = new CustomEvent<Theme>(ThemeChangeEventName, { detail: t });
      window.dispatchEvent(e);
    }
  };

  /* Set the default theme immediately */
  if (!theme.current) {
    const rt = randomTheme();
    theme.current = rt;
    updateTheme(rt);

    // Hack: dispatch an event a little bit after this
    // this should only ever be called once
    themeSetterTimeout.current = setTimeout(() => {
      updateTheme(theme.current!);
      themeSetterTimeout.current = undefined;
    }, 1000);
  }

  return (
    <ThemeContext.Provider value={{ theme: theme.current, setTheme }}>
      <div ref={rootRef} css={[TwThemeTransition, tw`bg-theme-bg`]}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
