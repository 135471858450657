import { motion, useAnimationFrame, useSpring } from 'framer-motion';
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import tw from 'twin.macro';
import { Emoji } from '~/components/atoms/Emoji';
import { EmojiInfo } from '~/config';
import { useIsMobile, useResize } from '~/hooks';
import { clamp, distance } from '~/utils';

export type ReactionProps = {
  index?: number;
  emoji: EmojiInfo;
  reactionDate: string;
  location: string;
  animation: 'confetti' | 'swarm';
  requestByPath?: ((path: string) => boolean) | null | false;
};

export const FLOAT_SPEED = 0.00008;
export const PARTICLE_MASS = 10;
export const CENTER_MASS = 1000;
export const GRAVITATIONAL_CONSTANT_DESKTOP = 50;
export const GRAVITATIONAL_CONSTANT_MOBILE = 0;
export const CENTER_POINT = {
  x: 0.5,
  y: 0.5,
};
export const GRAVITATIONAL_RADIUS_DESKTOP = 250;
export const GRAVITATIONAL_RADIUS_MOBILE = 0;
export const SCREEN_PADDING = 0;

export const Reaction: FC<ReactionProps> = ({
  index = 0,
  location,
  emoji,
  animation,
  requestByPath,
}) => {
  const buttonRef = useRef() as RefObject<HTMLButtonElement>;
  const mousePosition = useRef({ x: 0, y: 0 });

  const isMobile = useIsMobile();

  // Polar coordinates
  const radius = useRef(Math.random() * 0.65 + 0.35);
  const theta = useRef(Math.random() * 2 * Math.PI);

  const position = useRef({
    x: (radius.current * Math.cos(theta.current)) / 2 + CENTER_POINT.x,
    y: (radius.current * Math.sin(theta.current)) / 2 + CENTER_POINT.y,
  });

  const positionX = useSpring(0, {
    damping: 10,
    stiffness: 100,
  });
  const positionY = useSpring(0, {
    damping: 10,
    stiffness: 100,
  });

  const isFlipped = position.current.x > 0.5;

  const [stopMovement, setStopMovement] = useState(false);
  const [showIncrement, setShowIncrement] = useState(0);

  const live = useRef({
    time: 0,
    seed: {
      x: (0.5 + 0.5 * Math.random()) * Math.random() > 0.5 ? -1 : 1,
      y: (0.5 + 0.5 * Math.random()) * Math.random() > 0.5 ? -1 : 1,
      timeX: 0.5 + 0.5 * Math.random(),
      timeY: 0.5 + 0.5 * Math.random(),
    },
    pos: {
      x: {
        current: 0,
        velocity: 0,
      },
      y: {
        current: 0,
        velocity: 0,
      },
    },
    offset: {
      x: 0,
    },
  });

  const showBubble = () => {
    if (!stopMovement) {
      setStopMovement(true);
      live.current.offset.x = 0.005 * (isFlipped ? 1 : -1);
      setShowIncrement(showIncrement + 1);
    }
  };

  const hideBubble = () => {
    setStopMovement(false);
  };

  const dimensions = useResize();

  const onTick = (time, delta) => {
    if (!stopMovement) {
      live.current.time = time;
    }

    const { viewportWidth, viewportHeight } = dimensions.current;

    if (buttonRef.current) {
      const { x, y, width, height } = buttonRef.current.getBoundingClientRect();
      const distanceValue = distance(
        x,
        0.5 * viewportWidth,
        y,
        0.5 * viewportHeight,
      );

      if (!stopMovement) {
        const velocityX =
          0.025 *
          live.current.seed.x *
          Math.cos(
            live.current.time *
              FLOAT_SPEED *
              live.current.seed.timeX *
              Math.PI *
              2,
          ) *
          viewportWidth;
        const velocityY =
          0.025 *
          live.current.seed.y *
          -Math.sin(
            live.current.time *
              FLOAT_SPEED *
              live.current.seed.timeY *
              Math.PI *
              2,
          ) *
          viewportHeight;
        const gravityConstant = isMobile
          ? GRAVITATIONAL_CONSTANT_MOBILE
          : GRAVITATIONAL_CONSTANT_DESKTOP;

        const equationResult =
          (gravityConstant * (PARTICLE_MASS * CENTER_MASS)) /
          Math.pow(distanceValue * 0.5, 2);

        const unitVector = {
          x: (x - CENTER_POINT.x * viewportWidth) / distanceValue,
          y: (y - CENTER_POINT.y * viewportHeight) / distanceValue,
        };

        // Apply transforms

        // Have a different gravity radius for mobile and desktop
        const gravityRadius = isMobile
          ? GRAVITATIONAL_RADIUS_MOBILE
          : GRAVITATIONAL_RADIUS_DESKTOP;

        const xValue = clamp(
          (distanceValue > gravityRadius ? 0 : equationResult) * unitVector.x +
            velocityX +
            positionX.get(),
          window.innerWidth * (-position.current.x + SCREEN_PADDING),
          window.innerWidth * (1 - position.current.x - SCREEN_PADDING) - width,
        );
        const yValue = clamp(
          (distanceValue > gravityRadius ? 0 : equationResult) * unitVector.y +
            velocityY +
            positionY.get(),
          window.innerHeight * (-position.current.y + SCREEN_PADDING),
          window.innerHeight * (1 - position.current.y - SCREEN_PADDING) -
            height,
        );

        positionX.set(xValue);
        positionY.set(yValue);
      } else {
        positionX.set(
          mousePosition.current.x -
            position.current.x * window.innerWidth -
            width / 2,
        );
        positionY.set(
          mousePosition.current.y - position.current.y * window.innerHeight,
        );
      }
    }
  };

  const handleMouseEnter = (event) => {
    showBubble();
  };
  const handleMouseLeave = (event) => hideBubble();
  const handleMouseMove = (event) => {
    const { pageX, pageY } = event;
    mousePosition.current = {
      x: pageX,
      y: pageY,
    };
  };

  const randomPath = useMemo(() => {
    return [
      // Position contains decimal percentage x,y values so using vh and vw will size them appropriately
      `${(position.current.x + (Math.random() * 2 - 1) * 0.1) * 100}vw`,
      `${position.current.x * 100}vw`,
    ];
  }, []);

  const swarmVariants = useMemo(
    () => ({
      initial: {
        // Just enough so that they don't pop on screen
        y: `${110}vh`,
        x: `5vw`,
      },
      enter: (i) => ({
        // See above
        y: [`${110}vh`, `${position.current.y * 100}vh`],
        x: randomPath,
        transition: {
          duration: 5,
          ease: [0.165, 0.84, 0.44, 1],
          delay: (i % 15) * 0.2,
        },
      }),
      exit: (i) => ({
        opacity: 0,
        transition: {
          duration: 0.5,
          delay: (i % 10) * 0.1,
        },
      }),
    }),
    [randomPath],
  );

  const confettiVariants = useMemo(
    () => ({
      initial: {
        opacity: 0,
        y: `${position.current.y * 100}vh`,
        x: `${position.current.x * 100}vw`,
      },
      enter: {
        opacity: 1,
        y: `${position.current.y * 100}vh`,
        x: `${position.current.x * 100}vw`,
        transition: {
          default: {
            duration: 1,
            ease: [0.165, 0.84, 0.44, 1],
          },
          opacity: {
            duration: 0.25,
          },
        },
      },
      exit: (i) => ({
        opacity: 0,
        transition: {
          duration: 0.5,
          delay: (i % 10) * 0.1,
        },
      }),
    }),
    [],
  );

  useAnimationFrame(onTick);

  useEffect(() => {
    if (animation === 'confetti') {
      const x = position.current.x;
      const y = position.current.y;
      const confettiEvent = new CustomEvent('confetti', {
        detail: {
          origin: { x, y },
          particleCount: 150,
          spread: 360,
          startVelocity: 30,
        },
      });

      window.dispatchEvent(confettiEvent);
    }
  }, [animation]);

  const emojiVariants = useMemo(() => {
    return {
      initial: {
        scale: 1,
      },
      enter: {
        scale: [2, 3, 1],
        transition: {
          duration: 0.65,
          ease: 'easeOut',
        },
      },
      exit: (i) => ({
        scale: [1, 1.5, 0.25],
        transition: {
          duration: 0.5,
          delay: (i % 10) * 0.1,
        },
      }),
    };
  }, []);

  // trap permanent ref to image for fade-out, since it gets updated when the page changes
  const emojiRef = useRef<EmojiInfo>(emoji);

  const onClick =
    !!emoji.liveReactionPath && !!requestByPath
      ? () => requestByPath(emoji.liveReactionPath!)
      : undefined;

  return (
    <>
      <PositionDiv
        variants={animation === 'swarm' ? swarmVariants : confettiVariants}
        initial="initial"
        animate="enter"
        exit="exit"
        custom={index}
        className="group"
      >
        <motion.button
          css={[
            tw`flex items-center w-12 h-12 pointer-events-auto`,
            isFlipped ? tw`justify-end` : tw`justify-start`,
          ]}
          ref={buttonRef}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onClick}
          style={{
            x: positionX,
            y: positionY,
            cursor: !!onClick ? 'pointer' : 'default',
          }}
          aria-hidden
          tabIndex={-1}
        >
          <Emoji emoji={emojiRef.current} variants={emojiVariants} />

          {/* TOOLTIP */}
          <span
            tw="block absolute -translate-y-1/2 top-[50%]"
            css={[isFlipped ? tw`right-full -mr-3` : tw`left-full -ml-3`]}
          >
            <motion.span
              key={showIncrement}
              tw="
              relative opacity-0 group-hover:opacity-100 flex flex-col whitespace-nowrap px-3 py-1.5 bg-theme-fg rounded-md
              text-left font-tertiary text-[0.6875rem] leading-[1.38] tracking-[0.05em] text-theme-base-invert [transition: opacity .25s ease-in-out]
            "
              css={[isFlipped ? tw`mr-1.5` : tw`ml-1.5`]}
            >
              <svg
                tw="absolute top-1/2 block w-[1.3125rem] mt-[-0.25rem] fill-theme-fg"
                css={[isFlipped ? tw`left-full` : tw`right-full`]}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 35.6"
                transform={`translate(${isFlipped ? -8 : 8}, 0) rotate(${
                  isFlipped ? 90 : -90
                }) scale(0.85)`}
              >
                <path d="M65.5,10.95C61.4,4.02,56.77,.88,52.64,.25c-.85-.16-1.73-.25-2.63-.25-.85,0-1.68,.08-2.49,.22-4.17,.57-8.87,3.7-13.02,10.73C24.68,27.58,16.31,33.49,0,35.6H100c-16.31-2.1-24.68-8.02-34.5-24.64Z" />
              </svg>
              {emoji.liveReactionInitiative && (
                <em tw="font-light">{emoji.liveReactionInitiative}</em>
              )}
              <em tw="font-light">{location}</em>
            </motion.span>
          </span>
        </motion.button>
      </PositionDiv>
    </>
  );
};

const PositionDiv = tw(motion.div)`inline-block z-50 absolute`;
