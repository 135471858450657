import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash.debounce';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { DayContext } from '~/context/DayContext';
import { TutorialContext, TutorialStep } from '~/context/TutorialContext';
import { useResize } from '~/hooks';
import { dateFormatTense } from '~/utils/date';
import { easeOutQuart, easeOutQuint } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';
import { GlobeTrotterDial } from './GlobeTrotterDial';
import { GlobeTrotterIcon } from './GlobeTrotterIcon';

type GlobeTrotterProps = {
  fade: boolean;
};

const TOOLTIP_TIMEOUT = 100;

/**
 *
 */
export const GlobeTrotter: FC<GlobeTrotterProps> = ({ fade }) => {
  const { ready, day, requestByPath } = useContext(DayContext);
  const tutorial = useContext(TutorialContext);

  const badgeRef = useRef<HTMLDivElement>(null);
  const badgeOffsets = useRef({ right: 0, top: 0 });

  const [currentStep, setCurrentStep] = useState(0);
  const [dialActive, setDialActive] = useState(false);

  const dragInitiated = useRef(false);
  const dateSelectorTooltipShown = useRef(false);

  useResize(({ viewportWidth }) => {
    if (badgeRef.current) {
      const badgeBounds = badgeRef.current.getBoundingClientRect();
      badgeOffsets.current = {
        right: viewportWidth - badgeBounds.right + badgeBounds.width / 2,
        top: badgeBounds.top + badgeBounds.height / 2,
      };
    }
  });

  const handleDialCapture = () => {
    setDialActive(true);
    dragInitiated.current = true;
    if (tutorial.step === TutorialStep.GlobeTrotterDrag) {
      tutorial.goto(TutorialStep.Idle);
    }
  };

  const handleDialRelease = () => {
    setDialActive(false);
    if (!dateSelectorTooltipShown.current) {
      tutorial.goto(TutorialStep.DateSelectorOpen);
      dateSelectorTooltipShown.current = true;
    }
  };

  const handleDialStep = (direction: number) => {
    // Propagate navigation up to context
    const path = direction > 0 ? day.nextDay : day.prevDay;
    if (path) {
      requestByPath(path);
    }
  };

  // Apply stepping from context data
  useEffect(() => {
    if (day.direction) {
      setCurrentStep((i) => i + day.direction);
    }
  }, [day]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!dialActive && !dragInitiated.current) {
      timer = setTimeout(
        () => tutorial.goto(TutorialStep.GlobeTrotterDrag),
        TOOLTIP_TIMEOUT,
      );
    }
    return () => clearTimeout(timer);
  }, [tutorial, dialActive]);

  const show = {
    opacity: 1,
    y: 0,
  };

  const hideBackward = {
    opacity: 0,
    y: -5,
  };

  const hideForward = {
    opacity: 0,
    y: 5,
  };

  const isForward = day.direction >= 0;
  const hide = isForward ? hideForward : hideBackward;
  const initial = isForward ? hideBackward : hideForward;

  const leadingDuration = 0.1;
  const trailingDuration = 0.14;
  const trailingDelay = 0.03;

  const variants = {
    ready: {
      leave: anim({ opacity: 0, y: 0 }, 0), // instant
      enter: anim(
        { opacity: [0, 1, 1, 1], y: [0, 0, 4, 0] },
        0.55,
        0.13,
        easeOutQuart,
        [0, 0, 0.5, 1],
      ),
      initial: { opacity: 0, y: 0 },
    },
    firstLine: {
      leave: anim(hide, 0.07),
      enter: anim(
        show,
        isForward ? trailingDuration : leadingDuration,
        isForward ? 0 : trailingDelay,
        easeOutQuint,
      ),
      initial,
    },
    secondLine: {
      leave: anim(hide, 0.08),
      enter: anim(
        show,
        isForward ? leadingDuration : trailingDuration,
        isForward ? trailingDelay : 0,
        easeOutQuint,
      ),
      initial,
    },
  };

  return !day.path ? null : (
    <div
      tw="select-none fixed right-0 top-0 z-10 pr-4 pt-4 sm:pr-5"
      css={[
        { touchAction: 'none' },
        dialActive ? { cursor: 'grabbing' } : { cursor: 'grab' },
      ]}
    >
      <motion.div
        tw="relative flex items-center"
        css={[
          `
              &:hover {
                .badge .bg {
                  opacity: 1;
                  fill: var(--theme-base);
                }
              }
              .badge.active .bg {
                opacity: 1;
                fill: var(--theme-base);
              }
            `,
        ]}
        {...variant(variants.ready, ready)}
      >
        <AnimatePresence mode="wait">
          <p
            key={day.path}
            tw="pointer-events-none sm:pointer-events-auto z-10 max-w-[8rem] sm:max-w-[10rem] text-xs font-tertiary text-right text-theme-base"
          >
            <motion.span
              tw="sm:block font-thin"
              {...variant(variants.firstLine)}
            >
              {dateFormatTense(day.path, day.tense)}
            </motion.span>
            <span tw="sm:hidden">&nbsp;</span>
            <motion.span
              tw="sm:block sm:mt-0.5 font-medium [text-shadow: var(--theme-bg) 1px 0px 6px]"
              {...variant(variants.secondLine)}
            >
              {day.data?.initiative}
            </motion.span>
          </p>
        </AnimatePresence>
        <div
          ref={badgeRef}
          className={`badge${dialActive ? ' active' : ''}`}
          tw="relative flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20 ml-2"
        >
          <GlobeTrotterIcon currentStep={currentStep} fade={fade} />
          <GlobeTrotterDial
            offsets={badgeOffsets}
            onDragCapture={handleDialCapture}
            onDragRelease={handleDialRelease}
            onDragStep={debounce(handleDialStep, 200)}
            fade={fade}
          />
        </div>
      </motion.div>
    </div>
  );
};
