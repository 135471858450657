import sanityClient, { SanityClient } from '@sanity/client';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { navigate } from 'gatsby';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { PRELOAD_DURATION } from '~/components/Preloader';
import {
  Day,
  defaultHashtags,
  EmojiInfo,
  isSSG,
  LocalStorageKey,
  PageContext,
  ReactionData,
  ReactionFeed,
  sanityDataset,
} from '~/config';
import { useDaysQuery } from '~/hooks/useDaysQuery';
import { appendSlash, getStoredItem, storeItem } from '~/utils';
import { toPath } from '~/utils/date';

dayjs.extend(customParseFormat); // required for isValid() + strict
dayjs.extend(dayOfYear);

type DayContextProviderProps = {
  children?: ReactNode;
};

interface UserReactions {
  [key: string]: number;
}

const NAVIGATE_DEBOUNCE = 200; // Delay before navigating, allowing rapid adjustment without breakage

export const DayContext = createContext({
  ready: false,
  setIsReady: (val: boolean) => {},
  day: {} as Day,
  /** This is the floating-emoji reactions list. See `day` for `selectableReactions` and `customReaction`  */
  reactions: [] as ReactionData[],
  userReactions: {} as UserReactions,
  sharingHashtags: '',
  /** Navigate to a specific day page by its path */
  requestByPath: (path: string): boolean => true,
  todayPath: '',
});

export const DayContextProvider: FC<DayContextProviderProps> = ({
  children,
}) => {
  // stable - can be omitted from deps arrays!
  const {
    /** Data for all days, old to new */
    allDayData,
    /** Day paths */
    dayPaths,
    /** CMS _id of the Reaction model named 'Custom', which should be rendered using the day's custom icon/emoji */
    customReactionId,
    /** The four or so non-custom Reaction CMS models as EmojiInfos */
    stockReactions,
    /** Optimization for mapping incoming reactions efficiently */
    stockReactionsLookup,
  } = useDaysQuery();

  const [isReady, setIsReady] = useState(false);
  const [todayPath, setTodayPath] = useState<string>('');
  const [selectedDay, setSelectedDay] = useState<Day>({ path: null } as Day);
  const [hashtags, setHashtags] = useState(defaultHashtags);
  const [userReactions, setUserReactions] = useState<UserReactions>({});
  const selectedDayIndex = useRef(-1);
  const prevSelectedDayIndex = useRef(-1);
  const [reactions, setReactions] = useState<ReactionData[]>([]);
  const client = useRef<SanityClient>();

  // Sanity client is used to fetch floating reactions and sync live reactions from other users
  useEffect(() => {
    client.current = sanityClient({
      projectId: 'oqmae8kw',
      dataset: sanityDataset,
      apiVersion: '2021-10-21',
      useCdn: true,
    });
  }, []);

  // Set initial reaction state
  useEffect(() => {
    const localStorageReactions =
      getStoredItem(LocalStorageKey.reactionCount) || {};
    setUserReactions(localStorageReactions);

    return () => {};
  }, []);

  // Reaction state watcher to save to local storage on change
  useEffect(() => {
    storeItem(LocalStorageKey.reactionCount, userReactions);
  }, [userReactions]);

  // Fetch reactions for the day
  useEffect(
    () => {
      if (selectedDay.data && isReady) {
        console.info('fetch reactions!'); // TODO optimize for one main fetch per day landing
        client.current
          ?.fetch(
            // The below means: "grab all reactions ordered by date (resolving the "reaction" ref with the arrow)
            `*[_type == "reactionFeed" && dailyDifference->_id == "${
              selectedDay.data._id
            }"]{_id, reactionDate, location, "emojiId":reaction->_id}
              | order(dateTime(reactionDate) desc)[0...${
                isMobile ? 250 : 840
              }]`,
          )
          .then((result) => {
            setReactions(
              result.map((reaction) => ({
                ...reaction,
                emoji:
                  reaction.emojiId === customReactionId
                    ? { ...selectedDay.customReaction! }
                    : { ...stockReactionsLookup[reaction.emojiId] },
                animation: 'swarm',
              })),
            );
          });
      }
    },
    // stockReactionsLookup is from a Gatsby query and doesn't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedDay,
      isReady, // suppresses fetch during DatePicker selection
    ],
  );

  const getCustomReaction = useCallback(
    (data: PageContext) =>
      !data.icon && !data.emoji
        ? null
        : ({
            reactionName: 'Custom',
            id: customReactionId,
            imgSrc: data.icon?.theImage?.asset?.url,
            imgAlt: data.icon ? `${data.icon.name} emoji` : '', // there's no icon alt field, this might be useful for emoji selectors
            textEmoji: data.emoji,
          } as EmojiInfo),
    // stable deps omitted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Subscribe to updates:
  useEffect(
    () => {
      /** Translates the immutable "reaction" key/id into its display value  */
      let subscription;
      if (selectedDay.data) {
        subscription = client.current
          ?.listen<ReactionFeed>(`*[_type == "reactionFeed"]`)
          .subscribe((update) => {
            // "Appear" means a new document was added -- ignore updates/deletions
            if (update.result && update.transition === 'appear') {
              const reactionId = update.result.reaction!._ref;
              let emoji: EmojiInfo | null = null;
              const fromDayId = update.result.dailyDifference!._ref;

              if (fromDayId === selectedDay.data._id) {
                const source =
                  reactionId === customReactionId
                    ? { ...selectedDay.customReaction! }
                    : { ...stockReactionsLookup[reactionId] };
                emoji = { ...source };
              } else {
                // Handle special live-data case where a user from another page has clicked an emoji -
                // we can look it up and display it along with their initiative! 😀
                const fromDay = allDayData.find(
                  (d) => d.pageContext._id === fromDayId,
                )?.pageContext;
                if (fromDay) {
                  // use the custom emjoji from their day if it exists, more fun
                  const source = getCustomReaction(fromDay) ?? {
                    ...stockReactionsLookup[reactionId],
                  };
                  emoji = {
                    ...source,
                    liveReactionInitiative: fromDay.initiative!,
                    liveReactionPath: toPath(fromDay.theDate!),
                  };
                }
              }
              if (emoji) {
                setReactions([
                  ...reactions,
                  {
                    reactionDate: update.result.reactionDate!,
                    location: update.result.location!,
                    emoji: emoji,
                    animation: 'confetti',
                  },
                ]);
              }
            }
          });
      }
      return () => {
        subscription?.unsubscribe();
      };
    },
    // stable deps omitted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactions, selectedDay],
  );

  /** Get valid CMS hashtags and prepend with default #MakeADailyDifference */
  useEffect(() => {
    if (selectedDay?.data) {
      typeof selectedDay.data.hashtags === 'string'
        ? setHashtags(`${defaultHashtags} ${selectedDay.data.hashtags}`)
        : setHashtags(defaultHashtags);
    }
  }, [selectedDay]);

  /** Navigate to a specific day page by its path */
  const requestByPath = useCallback(
    (path: string) => {
      if (isSSG || path === '/') {
        return false;
      }
      if (selectedDay.path === path) {
        return true; // can't return false for this or date selector will think the date is not valid
      }
      prevSelectedDayIndex.current = selectedDayIndex.current;
      selectedDayIndex.current = dayPaths.indexOf(path);

      const dayByPath = allDayData.find((d) => d.path === path);

      // If this day exists, otherwise allow it to default to 404
      if (dayByPath) {
        const data = dayByPath.pageContext;
        const index = dayPaths.indexOf(path);
        const todaysIndex = dayPaths.indexOf(toPath(new Date())); // don't use todayPath here, it's unset on initial load and adds a dep
        const tense = index === todaysIndex ? 0 : index > todaysIndex ? 1 : -1;
        const direction =
          index === prevSelectedDayIndex.current
            ? 0
            : index > prevSelectedDayIndex.current
            ? 1
            : -1;

        // Preset previous & next paths
        // TODO missing-date logic (e.g. we have 1/1 and 1/3 but the url is 1/2) which will require locating the gap in the dataset.
        // Skipping for now because that's hard and we plan to launch with a tightly-packed series.
        const nm = toPath(dayjs(data.theDate).add(1, 'month').toDate());
        const nextMonth = dayPaths.includes(nm) ? nm : undefined;
        const pm = toPath(dayjs(data.theDate).subtract(1, 'month').toDate());
        const prevMonth = dayPaths.includes(pm) ? pm : undefined;
        const nextDay: string | undefined =
          index !== -1 && index < dayPaths.length - 1
            ? dayPaths[index + 1]
            : undefined;
        const prevDay: string | undefined =
          index !== -1 && index > 0 ? dayPaths[index - 1] : undefined;
        const customReaction = getCustomReaction(data);
        const appropriateStockReactions = data.noLaughingMatter
          ? [...stockReactions.filter((r) => r.reactionName !== 'Smile')]
          : [...stockReactions];
        const selectableReactions = customReaction
          ? [...appropriateStockReactions, customReaction]
          : [...appropriateStockReactions];

        const selected: Day = {
          path: dayByPath.path,
          data,
          index,
          customReaction,
          selectableReactions,
          tense,
          direction,
          nextMonth,
          prevMonth,
          nextDay,
          prevDay,
        };
        setReactions([]); // start animating the reactions out on day change. during date picking this prevents the old ones from hanging out until the new date is picked.
        setSelectedDay(selected);

        return true;
      } else {
        // else allow 404
        setIsReady(true);
        return false;
      }
    },
    // stable deps omitted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDay],
  );

  // Set today and landing day
  useEffect(
    () => {
      if (!isSSG) {
        const today = toPath(new Date());
        setTodayPath(today);

        // If the route contains a date path use that as the landing Day
        // otherwise use "today" (or the most recent date to it which exists)
        const urlPath = appendSlash(window.location.pathname);
        const path = urlPath === '/' ? today : urlPath;
        if (path === today) {
          // we need to replace the url first to avoid a dead back-button route
          navigate(path, { replace: true });
        }
        if (path) {
          requestByPath(path);
        }
        // else allow 404
      }
    },
    // once on landing only!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Update on route change (e.g. browser back/fwd)
  useEffect(() => {
    if (!isSSG) {
      const handler = () => {
        const urlPath = appendSlash(window.location.pathname);
        if (urlPath && urlPath !== '/') {
          requestByPath(urlPath);
        }
      };
      // Not typical but needed here to determine isolated back/fwd event outside of other async state changes
      window.addEventListener('popstate', handler);
      return () => window.removeEventListener('popstate', handler);
    }
  }, [requestByPath]);

  /** Respond to reaction event */
  const handleReaction = useCallback(
    (event) => {
      if (selectedDay.data.theDate) {
        // If there's an existing reaction for this day then add 1
        if (userReactions[selectedDay.data.theDate]) {
          setUserReactions({
            ...userReactions,
            [selectedDay.data.theDate]:
              userReactions[selectedDay.data.theDate] + 1,
          });
          // Otherwise set to 1
        } else {
          setUserReactions({
            ...userReactions,
            [selectedDay.data.theDate]: 1,
          });
        }
      }
    },
    [selectedDay, userReactions],
  );

  // Add reaction event listener
  useEffect(() => {
    window.addEventListener('reaction', handleReaction);

    return () => {
      window.removeEventListener('reaction', handleReaction);
    };
  }, [handleReaction]);

  // Navigate to current day debounced
  useEffect(() => {
    let timer = setTimeout(() => {
      if (
        !isSSG &&
        !!selectedDay.path /* initial state */ &&
        selectedDay.path !== window.location.pathname
      ) {
        navigate(selectedDay.path);
      }
    }, NAVIGATE_DEBOUNCE);
    return () => clearTimeout(timer);
  }, [selectedDay]);

  // Provide `ready` state
  useEffect(() => {
    let timer = setTimeout(() => setIsReady(true), PRELOAD_DURATION + 50);
    return () => clearTimeout(timer);
  }, []);

  // Add keybindings
  useEffect(() => {
    const handleKeyup = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowRight':
          selectedDay.nextDay && requestByPath(selectedDay.nextDay);
          break;
        case 'ArrowLeft':
          selectedDay.prevDay && requestByPath(selectedDay.prevDay);
          break;
      }
    };

    document.addEventListener('keyup', handleKeyup, false);

    return () => {
      document.removeEventListener('keyup', handleKeyup, false);
    };
  }, [requestByPath, selectedDay]);

  return (
    <DayContext.Provider
      value={{
        ready: isReady,
        /** Only exposed so the DatePicker can turn off content easily when shown */
        setIsReady,
        day: selectedDay,
        sharingHashtags: hashtags,
        userReactions,
        /** This is the floating-emoji reactions list. See `day` for `selectableReactions` and `customReaction`  */
        reactions,
        requestByPath,
        todayPath,
      }}
    >
      {children}
    </DayContext.Provider>
  );
};
