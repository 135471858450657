/**
 * Clamp a numerical value within the provided range
 *
 * @param val  Value to clamp
 * @param min  Minimum value
 * @param max  Maximum value
 */
export const clamp = (val: number, min: number, max: number) =>
  Math.min(Math.max(val, min), max);

/**
 * Tweaked modulo function to handle negative numbers correctly
 *
 * @param n Number to perform operation on
 * @param m Number to modulo by
 * @returns Modulus value
 */
export const mod = (n: number, m: number) => ((n % m) + m) % m;

/**
 * Get point coordinates at theta (angle) around a circle
 * Assumes a unit circle by default
 *
 * @param theta Angle around circle in radians
 * @param radius Radius of the circle
 * @param x X translation
 * @param y Y translation
 * @returns Array containing point coordinates in series
 */
export const pointOnCircle = (theta: number, radius = 1, x = 0, y = 0) => [
  x + radius * Math.cos(theta),
  y + radius * Math.sin(theta),
];

/**
 * Calculate the magnitude (length, hypotenuse) of a 2D vector
 * @param x X value
 * @param y Y value
 * @returns Magnitude of the vector
 */
export const magnitude = (x: number, y: number) => Math.sqrt(x * x + y * y);

/**
 * Normalize a 2D vector
 * @param x X value
 * @param y Y value
 * @returns Array containing normalized values in series
 */
export const normalize = (x: number, y: number) => {
  const mag = magnitude(x, y);
  return !mag ? [0, 0] : [x / mag, y / mag];
};

/**
 * Calculate an update step between a target and current value using spring physics
 * https://blog.maximeheckel.com/posts/the-physics-behind-spring-animations
 * @param target Target value being moved towards
 * @param current Current value, as calculated from previous step
 * @param velocity Current velocity, as calculated from previous step
 * @param damping Normalized damping factor (determines how "springy" movement is, with lower numbers taking longer to arrive at equilibrium)
 * @param tension Normalized tension factor (determines how "stiff" the spring is, with lower numbers moving more slowly)
 * @returns The updated `current` and `velocity` values, pass these back in on the next step
 */
export const springStep = (
  target: number,
  current: number,
  velocity: number,
  config: { damping: number; tension: number; factor: number } = {
    damping: 0.3,
    tension: 0.1,
    factor: 1,
  },
) => {
  const PRECISION = 0.0001;
  const velocityStep =
    (velocity + (target - current) * config.tension * config.factor) *
    (1 - config.damping);
  const currentStep =
    Math.round((current + velocityStep) * (1 / PRECISION)) / (1 / PRECISION);
  return { current: currentStep, velocity: velocityStep };
};

/**
 * A random number function that follows a normal distribution
 * @returns a value between [0,1]
 */
export const normalizedRandom = () => {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) return normalizedRandom(); // resample between 0 and 1
  return num;
};

export const distance = (xOne, xTwo, yOne, yTwo) => {
  return Math.sqrt(Math.pow(xOne - xTwo, 2) + Math.pow(yOne - yTwo, 2));
};
