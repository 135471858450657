import confetti from 'canvas-confetti';
import { createContext, FC, useCallback, useEffect, useRef } from 'react';
import { CustomCssProps } from '~/config';
import { delay } from '~/utils';

type Canvas2DProps = CustomCssProps & {
  children?: React.ReactNode;
};

export const CanvasContext = createContext<{
  context: CanvasRenderingContext2D | null;
  ref: HTMLCanvasElement | null;
}>({
  context: null,
  ref: null,
});

const MAX_CONFETTI = 5;

/**
 *
 */
export const Canvas2D: FC<Canvas2DProps> = ({ children }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const context = useRef<CanvasRenderingContext2D | null>(null);
  const confettiCannon = useRef<confetti.CreateTypes>();
  const confettiBacklog = useRef<any[]>([]);

  const handleConfetti = useCallback((event) => {
    // Do not fire confetti if browser is inactive or hidden
    if (confettiCannon.current) {
      if (!document.hidden) {
        confettiCannon.current({
          ...event.detail,
        });
      } else {
        if (confettiBacklog.current.length >= MAX_CONFETTI) {
          confettiBacklog.current.pop();
        }
        confettiBacklog.current.push(event.detail);
      }
    }
  }, []);

  const handleVisibilityChange = useCallback(async (event) => {
    if (!document.hidden && confettiBacklog.current) {
      for (const details of confettiBacklog.current) {
        if (confettiCannon.current) {
          confettiCannon?.current?.(details);
          await delay(0.2);
        }
      }
      confettiBacklog.current = [];
    }
  }, []);

  const setRef = useCallback((ref) => {
    ref.height = window.innerHeight * 4;
    ref.width = window.innerWidth * 4;
    canvasRef.current = ref;

    if (ref) {
      ref.height = window.innerHeight;
      ref.width = window.innerWidth;
      context.current = ref.getContext('2d');

      if (ref.tagName.toUpperCase() === 'CANVAS') {
        confettiCannon.current = confetti.create(ref, {
          resize: true,
        });
      }
      if (
        canvasRef.current &&
        canvasRef.current.tagName.toUpperCase() === 'CANVAS'
      ) {
        confettiCannon.current = confetti.create(
          canvasRef.current as HTMLCanvasElement,
          {
            resize: true,
          },
        );
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('confetti', handleConfetti);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('confetti', handleConfetti);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleConfetti]);

  return (
    <canvas
      ref={setRef}
      id="canvas"
      tw="fixed inset-0 z-10 w-full h-full pointer-events-none"
    >
      <CanvasContext.Provider
        value={{ context: context.current, ref: canvasRef.current }}
      >
        {children}
      </CanvasContext.Provider>
    </canvas>
  );
};
