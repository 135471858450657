import fetch from 'cross-fetch';
import { motion, Variants } from 'framer-motion';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import tw from 'twin.macro';
import { Emoji } from '~/components/atoms/Emoji';
import { JauntyButton } from '~/components/atoms/JauntyButton';
import { GooEffectSvg } from '~/components/common';
import { DayContext } from '~/context/DayContext';
import { tagEmojiClick } from '~/utils/tagging';

const effectId = 'reaction-menu-effect';

type ReactionSelectorProps = {
  onSent?: () => void;
};
const BOUNCE_INTERVAL = 6;
export const REACTION_LIMIT = 30;

/**
 * Simple example of grabbing a set of data from Sanity via the graph and turning into a <select>
 */
export const ReactionSelector: FC<ReactionSelectorProps> = ({
  onSent = () => {},
}) => {
  const { day, userReactions } = useContext(DayContext);
  const [hasHoveredOnce, setHasHoveredOnce] = useState(false);
  const [maxReactions, setMaxReactions] = useState(false);

  const [emojiClicked, setEmojiClicked] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const emojiContainerRef = useRef<HTMLDivElement>(null);

  const setButtonDisabled = (state) => {
    if (emojiContainerRef.current) {
      const buttons = emojiContainerRef.current.children;
      for (let i = 0; i < buttons.length; i++) {
        (buttons[i] as HTMLButtonElement).disabled = state;
      }
    }
    if (buttonRef.current) buttonRef.current.disabled = true;
  };
  const bounceInterval = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (day.path && !hasHoveredOnce) {
      bounceInterval.current = setTimeout(() => {
        setEmojiClicked(
          day.customReaction?.id ?? day.selectableReactions[0].id,
        );
      }, BOUNCE_INTERVAL * 1000);
    }
    return () => {
      if (bounceInterval.current) {
        clearInterval(bounceInterval.current);
      }
    };
  }, [day, hasHoveredOnce]);

  // Watch date change to toggle button disabled state
  useEffect(() => {
    if (
      day.data &&
      day.data.theDate &&
      userReactions[day.data.theDate] >= REACTION_LIMIT
    ) {
      setButtonDisabled(true);
      setOpen(false);
      setMaxReactions(true);
    } else {
      setButtonDisabled(false);
      setMaxReactions(false);
    }
  }, [day, maxReactions, userReactions]);

  // Auto-close during date change (for iPad, mainly)
  useEffect(() => setOpen(false), [day]);

  const handleEmojiClick = useCallback(
    async (e) => {
      if (!day.path || !day?.data?.theDate) {
        return;
      }

      // Not DRY for just readability
      // Check if user has maxed out reactions
      if (userReactions[day.data.theDate] >= REACTION_LIMIT) {
        return;
      }

      const id = e.currentTarget.id;
      setEmojiClicked(id);

      setButtonDisabled(true);

      await fetch('/api/reaction', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          r: id,
          dayId: day.data._id,
        }),
      });

      // To test click state
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      const event = new CustomEvent('reaction');
      window.dispatchEvent(event);

      setEmojiClicked(null);
      onSent();
    },
    [day, onSent, userReactions],
  );

  const buttonVariants = useCallback(
    (index) =>
      ({
        open: {},
        closed: {
          y: 0,
          transition: {
            duration: 0.35,
            delay: 0.025 * index,
          },
        },
        clicked: {
          y: ['0%', '-100%'],
          transition: {
            y: {
              duration: 0.4,
              repeatType: 'reverse',
              repeat: Infinity,
              ease: 'easeOut',
            },
          },
        },
      } as Variants),
    [],
  );

  const positionVariants = useMemo(
    () => (i) => {
      return {
        open: (i) => ({
          x: `${i * 4 + 0.5}rem`,
          transition: {
            duration: 0.5,
            delay: 0.05 * i,
          },
        }),
        closed: (i) => ({
          x: '-4rem',
          transition: {
            duration: 0.5,
            delay: 0.025 * i,
          },
        }),
      };
    },
    [],
  );

  const handleMouseOver = useCallback(() => {
    setHasHoveredOnce(true);
    setEmojiClicked(null);
    if (!maxReactions) {
      setOpen(true);
    }
  }, [maxReactions]);
  const handleMouseOut = useCallback(() => {
    setOpen(false);
  }, []);

  const effectTransform = tw`[transform:scale(0.9)] origin-[30%]`;

  return !day.path ? null : (
    <div tw="mt-5">
      <motion.div
        tw="relative"
        animate={open ? 'open' : 'closed'}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {
          /* Elaborate way to be able to use the 'goo' effect on expand with the
             special hand-drawn button outlines, without the blur showing at rest:
             animate a duplicate set of buttons that's slightly scaled down 😶 */
          ['effect', 'ui'].map((layer) => (
            <div
              key={layer}
              css={
                layer === 'effect'
                  ? tw`absolute [filter:url(#goo-${effectId})]`
                  : tw`relative`
              }
            >
              <BubbleWrapper ref={emojiContainerRef}>
                {day.selectableReactions?.map((emoji, index) => {
                  return (
                    <PositionDiv
                      custom={index}
                      key={`button-${emoji.id}`}
                      variants={positionVariants(index)}
                    >
                      <div css={layer === 'effect' && effectTransform}>
                        <JauntyButton
                          customCss={[
                            tw`w-[52px] h-[52px] p-0 mt-px left-[calc(100% - 4rem)]`,
                          ]}
                          animate={
                            emojiClicked === emoji.id
                              ? 'clicked'
                              : open
                              ? 'open'
                              : 'closed'
                          }
                          variants={buttonVariants(index)}
                          id={emoji.id}
                          onClick={
                            layer === 'ui'
                              ? (e) => {
                                  tagEmojiClick(
                                    `emoji_${emoji.reactionName.toLowerCase()}`,
                                    day.data.theDate!,
                                    day.data.initiative!,
                                  );
                                  handleEmojiClick(e);
                                }
                              : undefined
                          }
                          aria-label={`React with ${
                            emoji.imgAlt || emoji.textEmoji
                          } emoji`}
                          // prevent screenreaders from reading out buttons twice
                          ariaHidden={layer === 'effect' ? true : false}
                          tabIndex={layer === 'effect' ? -1 : 0}
                        >
                          {layer === 'ui' && (
                            <Emoji
                              emoji={emoji}
                              customCss={tw`text-xl m-auto block md:(w-7 h-7)`}
                            />
                          )}
                        </JauntyButton>
                      </div>
                    </PositionDiv>
                  );
                })}
              </BubbleWrapper>

              <JauntyButton
                onTouchStart={() => layer === 'ui' && setOpen(!open)}
                onFocus={() => layer === 'effect' && setOpen(!open)}
                customCss={[
                  tw`relative px-10`,
                  layer === 'effect'
                    ? effectTransform
                    : tw`min-h-[54px] /* 2px taller to fully hide buttons behind it */`,
                  ,
                ]}
                ariaHidden={layer === 'ui' ? true : false}
                tabIndex={layer === 'ui' ? -1 : 0}
              >
                {maxReactions
                  ? "Thanks for making someone's day"
                  : "Make someone's day"}
              </JauntyButton>
            </div>
          ))
        }
      </motion.div>

      <GooEffectSvg svgId={effectId} />
    </div>
  );
};

const PositionDiv = tw(motion.div)`absolute px-4`;

const BubbleWrapper = tw(motion.div)`
flex flex-row gap-4 absolute top-0 right-0
`;
