import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { easeOutQuart } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';

type TooltipProps = {
  children: ReactNode;
  visible: boolean;
};

const variants = {
  bubble: {
    leave: anim({ opacity: [1, 0], y: [0, 5] }, 0.15, 0, easeOutQuart),
    enter: anim(
      { opacity: [0, 1, 1, 1], y: [0, 0, 5, 0] },
      1,
      0.25, // Ensure there's always a gap between previous and next tooltip
      easeOutQuart,
      [0, 0, 0.33, 1],
    ),
    initial: { opacity: 0, y: 0 },
  },
};

/**
 *
 */
export const Tooltip: FC<TooltipProps> = ({ children, visible = false }) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          tw="absolute right-0 top-0 px-3 py-2.5 bg-theme-base rounded-lg"
          {...variant(variants.bubble)}
        >
          <svg
            tw="absolute right-[1.125rem] bottom-full block w-[1.3125rem] -mb-px fill-theme-base"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 35.6"
          >
            <path d="M65.5,10.95C61.4,4.02,56.77,.88,52.64,.25c-.85-.16-1.73-.25-2.63-.25-.85,0-1.68,.08-2.49,.22-4.17,.57-8.87,3.7-13.02,10.73C24.68,27.58,16.31,33.49,0,35.6H100c-16.31-2.1-24.68-8.02-34.5-24.64Z" />
          </svg>
          <span
            tw="block whitespace-nowrap text-theme-base-invert
        font-tertiary text-[0.75rem] leading-none tracking-[0.01em] font-medium"
          >
            {children}
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
