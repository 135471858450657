import { FC, useContext, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import { DayContext } from '~/context/DayContext';
import { useRaf } from '~/hooks/useRaf';
import { clamp, mod, springStep } from '~/utils/math';

/*
  Total frame count as per frame sequence assets
*/
const FRAME_COUNT = 60;

/* 
  Two options:
  1. FRAME_COUNT could be evenly divisible by this for a perfect loop, 
     where each step around the globe lands in the same spot each time.
  2. Not evenly divisible, still works, but each step varies a bit on 
     each rotation.
*/
const FRAMES_PER_STEP = 13;

/* 
  Which step to start on, i.e. at which position around the globe
*/
const STARTING_STEP = 1; // Starts on the Americas

type GlobeTrotterIconProps = {
  currentStep: number;
  fade: boolean;
};

/**
 *
 */
export const GlobeTrotterIcon: FC<GlobeTrotterIconProps> = ({
  currentStep,
  fade,
}) => {
  const { ready } = useContext(DayContext);

  const framesRef = useRef<HTMLImageElement[] | null[]>([]);

  const live = useRef({
    step: STARTING_STEP,
    frame: {
      current: STARTING_STEP * FRAMES_PER_STEP,
      velocity: 0,
    },
    entry: 1,
  });

  useEffect(() => {
    if (ready) {
      live.current.entry = 1;
    }
  }, [ready]);

  useEffect(() => {
    live.current.step = STARTING_STEP + currentStep;
  }, [currentStep]);

  const onTick = ({ elapsed, factor }) => {
    const count = framesRef.current.length;

    // Decay entry spin
    if (ready) {
      live.current.entry *= clamp(1 - 0.07 * factor, 0.86, 1);
    }

    // Update spring for frame interpolation
    const springConfig = { factor, damping: 0.5, tension: 0.1 };
    live.current.frame = springStep(
      live.current.entry * FRAME_COUNT * -0.5 +
        live.current.step * FRAMES_PER_STEP,
      live.current.frame.current,
      live.current.frame.velocity,
      springConfig,
    );

    // Reset all frames
    for (let i = 0; i < count; i++) {
      const frame = framesRef.current[i];
      if (frame) {
        frame.style.opacity = '0';
        frame.style.transform = 'none';
      }
    }

    // Apply mod to loop sequence at end
    const currentFrameIndex = mod(
      Math.floor(live.current.frame.current),
      FRAME_COUNT,
    );

    // Set the current frame
    const currentFrame = framesRef.current[currentFrameIndex];
    if (currentFrame) {
      currentFrame.style.opacity = '1';
      currentFrame.style.transform = `scale(${
        1 +
        0.15 * Math.pow(Math.min(Math.abs(live.current.frame.velocity), 1), 1.5)
      })`;
    }
  };

  useRaf(true, onTick);

  return (
    <div tw="relative w-full h-full">
      <svg
        tw="hidden sm:block absolute inset-0 fill-theme-base-invert transition duration-200 ease-out-quart"
        className="bg"
        viewBox="0 0 83 82"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M67.395 10.9891C68.4979 11.7809 69.3246 12.5974 70.0954 13.5587C70.167 13.6371 70.2409 13.7169 70.3125 13.7952C74.5695 18.4838 77.9365 24.122 79.2873 30.6448C79.7355 33.2128 81.1009 35.3343 81.69 38.1071C81.705 38.2147 81.7186 38.3247 81.7322 38.4347C82.7509 46.3974 81.3009 54.4646 76.3957 61.2812C76.3621 61.3271 76.3284 61.3731 76.2933 61.4215C75.0412 63.0627 73.7128 64.6557 72.3096 66.1815C72.2355 66.2629 72.1614 66.3442 72.0834 66.4266C68.1065 72.6616 61.9832 76.3412 55.3317 78.5705C55.2293 78.6056 55.1244 78.6392 55.0196 78.6728C46.6724 81.515 37.6805 83.4539 29.2933 80.1348C29.1891 80.1063 29.0849 80.0777 28.9798 80.0454C25.7506 79.1157 22.6989 77.6521 19.9941 75.4782C19.8995 75.4224 19.8073 75.368 19.7141 75.3098C15.6053 72.838 11.9023 69.7168 9.01697 65.9686C8.95219 65.8844 8.88498 65.7987 8.82164 65.712C6.27178 62.9646 5.38354 59.479 3.86598 55.9514C3.5699 54.7895 2.64448 53.5857 2.10573 52.2807C0.641561 49.772 1.35337 46.9006 1.82251 43.8861C1.91309 42.6231 0.888987 41.032 0.979571 39.769C0.998562 38.2169 1.09702 36.6854 1.28079 35.1812C1.28729 35.1257 1.2938 35.0703 1.30174 35.0125C1.70424 29.6649 5.16797 25.454 6.74369 21.1174C11.6148 7.86317 25.8226 3.7338 38.3931 1.272C41.4864 0.462905 44.6331 0.672612 47.7798 0.882315C55.8625 1.04025 61.2629 6.85706 67.2064 10.9207C67.2718 10.9428 67.3358 10.9674 67.395 10.9891Z" />
      </svg>
      <div
        tw="relative w-full h-full sm:w-16 sm:h-16 sm:m-2 transition-opacity duration-200 ease-out-quart"
        css={[fade && tw`opacity-0`]}
      >
        {[...Array(FRAME_COUNT)].map((x, i) => (
          <img
            ref={(el) => (framesRef.current[i] = el)}
            tw="absolute inset-0"
            key={i}
            src={`/images/sequence/globe/globe-${i}.png`}
            alt=""
            draggable="false"
          />
        ))}
      </div>
    </div>
  );
};
