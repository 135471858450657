import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { DayContextProvider } from '~/context/DayContext';
import { RAFContextProvider } from '~/context/RAFContext';
import { ThemeContextProvider } from '~/context/ThemeContext';
import { TutorialContextProvider } from '~/context/TutorialContext';
import { DateSelector } from './DateSelector';
import { GlobeTrotter } from './GlobeTrotter';
import { Logo } from './Logo';
import { Canvas2D as ConfettiCanvas } from './organisms/Canvas2D';
import { PixiReactions, ReactionSelector } from './organisms/Reactions';
import { Preloader } from './Preloader';
import { YallCredit } from './YallCredit';

type LayoutProps = { children?: ReactNode };

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [isPreloadComplete, setIsPreloadComplete] = useState(false);
  const [isDateSelectorHovering, setIsDateSelectorHovering] = useState(false);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);

  return (
    <DayContextProvider>
      <ThemeContextProvider>
        <TutorialContextProvider>
          <RAFContextProvider enabled>
            <Main>
              {/* FLOATING EMOJIS */}
              <PixiReactions />
              <MobileDimmer />
              <ConfettiCanvas />

              <FloatContent>
                {/* HEADER */}
                <div tw="[grid-row:1] [grid-column: 1/4] w-full">
                  <MobileNavBar />
                  <Logo />
                  <GlobeTrotter
                    fade={isDateSelectorHovering || isDateSelectorOpen}
                  />
                </div>

                {/* DAY CONTENTS */}
                <div tw="[grid-row:2] [grid-column: 1/4] w-full">
                  <AnimatePresence mode="wait">{children}</AnimatePresence>
                </div>

                {/* Y'ALL FOOTER */}
                <div tw="z-0 text-center [line-height: 1rem] flex items-end [grid-row: 4] [grid-column: 1/4] px-6 pb-4 sm:(fixed inset-x-0 bottom-6 flex justify-center items-center p-0)">
                  <motion.span
                    tw="text-xs font-tertiary text-theme-base"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 4 }}
                  >
                    We aren't affiliated with any organization or person in the
                    above links.
                    <br tw="hidden sm:block" /> This is just a collection of
                    solid resources to help you make a daily difference!
                  </motion.span>
                </div>
                <div tw="[grid-column: 1/4] [grid-row:3] grid [align-content:end] sm:[grid-column: 3]">
                  <YallCredit />
                </div>
              </FloatContent>

              {/* REACTION SELECTOR */}
              <div tw="fixed z-10 left-0 bottom-0 pl-7 pb-4 hidden sm:(block bottom-16) lg:(bottom-0)">
                <ReactionSelector />
              </div>

              <DateSelector
                onButtonEnter={() => setIsDateSelectorHovering(true)}
                onButtonLeave={() => setIsDateSelectorHovering(false)}
                onOpen={() => setIsDateSelectorOpen(true)}
                onClose={() => setIsDateSelectorOpen(false)}
              />
            </Main>
            {!isPreloadComplete && (
              <Preloader onComplete={() => setIsPreloadComplete(true)} />
            )}
          </RAFContextProvider>
        </TutorialContextProvider>
      </ThemeContextProvider>
    </DayContextProvider>
  );
};

const Main = tw.main`relative bg-theme-bg`;

const MobileNavBar = tw.div`z-10 fixed top-0 inset-x-0 h-20 bg-theme-bg shadow-theme-bg shadow-md sm:(hidden)`;

const FloatContent = tw.div`
  min-h-screen
  // grid works well here to keep the content centered and the footer at the bottom, while protecting the mobile top nav area
  grid [grid-template-rows: minmax(6rem, 1fr) auto 1fr] justify-items-center
`;

const MobileDimmer = styled.div(() => [
  tw`absolute inset-0 md:hidden`,
  css`
    background: linear-gradient(
      to bottom,
      var(--theme-bg-faded-heavy) 0%,
      var(--theme-bg-faded-heavy) 30%,
      var(--theme-bg-faded-light) 45%,
      var(--theme-bg-faded-light) 65%,
      var(--theme-bg-faded-heavy) 70%,
      var(--theme-bg-faded-heavy) 80%,
      var(--theme-bg-faded-light) 85%,
      var(--theme-bg-faded-light) 100%
    );
  `,
]);
