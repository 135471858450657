import { MotionProps } from 'framer-motion';

/**
 * Consolidation helper for framer-motion allowing more
 * shorthand animation prop + transition declaration.
 * Should be used in conjunction with variants.
 *
 * E.g:
 * const animationProps = anim({opacity: 1}, 0.5, 0.1, 'easeInOut')
 *
 * vs.
 *
 * const animationProps = {
 *  opacity: 1,
 *  transition: {
 *    duration: 0.5,
 *    delay: 0.1,
 *    ease: 'easeInOut'
 *  }
 * }
 *
 * @param input Object of motion props (e.g. `opacity`, `y`) and the values to animate to
 * @param duration Transition duration
 * @param delay Transition delay
 * @param ease Transition ease, as per framer docs: https://www.framer.com/docs/transition/###ease
 * @param ease Transition keyframe times, as per framer docs: https://www.framer.com/docs/animation/##keyframes
 * @returns Animation props
 */
export const anim = (
  input: object,
  duration = 0.5,
  delay = 0,
  ease: string | ((a: number) => number) = 'easeOut',
  times?: number[],
) => ({
  ...input,
  transition: {
    duration,
    delay,
    ease,
    times,
  },
});

/**
 * Consolidation helper for framer-motion allowing more
 * shorthand variant declaration within template markup:
 *
 * E.g:
 * const someVariantObject = {
 *   initial: anim({ opacity: 0 }, 0.5)
 *   leave: anim({ opacity: 0 }, 0.5),
 *   enter: anim({ opacity: 1 }, 0.5),
 * }
 * ...
 * <motion.div {...variant(someVariantObject)} />
 *
 * @param v Variants object containing initial, enter, leave motion props (i.e. generated from anim() helper)
 * @param apply Whether apply the variant, or just apply it's initial state. Can be used to time entry animation
 * @returns Spreadable React props as MotionProps
 */
export const variant = (
  v: { initial: object; enter: object; leave: object },
  apply: boolean = true,
) =>
  apply
    ? ({
        variants: v,
        initial: 'initial',
        animate: 'enter',
        exit: 'leave',
      } as MotionProps)
    : { initial: v.initial };
