import { useCallback, useEffect } from 'react';
import { browserName } from 'react-device-detect';

const options = {
  mouseMultiplier: 1,
  touchMultiplier: 2,
  firefoxMultiplier: 15,
};

export interface NormalizedWheelEvent {
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
  originalEvent: WheelEvent;
}

export const useNormalizedMouseWheel = (
  callback: (arg0: NormalizedWheelEvent) => void,
) => {
  const handleWheel = useCallback(
    (e) => {
      const evt = { ...e };

      // In Chrome and in Firefox (at least the new one)
      evt.deltaX = e.wheelDeltaX || e.deltaX * -1;
      evt.deltaY = e.wheelDeltaY || e.deltaY * -1;

      // for our purpose deltamode = 1 means user is on a wheel mouse, not touch pad
      // real meaning: https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#Delta_modes
      if (browserName === 'Firefox' && e.deltaMode == 1) {
        evt.deltaX *= options.firefoxMultiplier;
        evt.deltaY *= options.firefoxMultiplier;
      }

      evt.deltaX *= options.mouseMultiplier;
      evt.deltaY *= options.mouseMultiplier;

      callback({
        x: evt.x,
        y: evt.y,
        deltaX: evt.deltaX,
        deltaY: evt.deltaY,
        originalEvent: e,
      });
    },
    [callback],
  );

  // Attach different wheel event listeners
  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel]);
};
