import { motion } from 'framer-motion';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { DayContext } from '~/context/DayContext';
import { TwThemeTransition } from '~/context/ThemeContext';
import { easeOutExpo, easeOutQuart } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';

type LogoProps = {
  //
};

/**
 *
 */
export const Logo: FC<LogoProps> = (props) => {
  const { day, ready, requestByPath, todayPath } = useContext(DayContext);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsHovered(false);
  }, [day]);

  const variants = useMemo(() => {
    const enterDelay = 0.5;
    return {
      ready: {
        leave: anim({ opacity: 0, y: 0 }, 0), // instant
        enter: anim(
          { opacity: [0, 1, 1, 1], y: [0, 0, 4, 0] },
          0.55,
          0,
          easeOutQuart,
          [0, 0, 0.5, 1],
        ),
        initial: { opacity: 0, y: 0 },
      },
      firstLine: {
        leave: anim({ x: '-100%' }, 0),
        enter: anim({ x: 0 }, 2.4, enterDelay + 0.08, easeOutExpo),
        initial: { x: '-100%' },
      },
      secondLine: {
        leave: anim({ x: '-100%' }, 0),
        enter: anim({ x: 0 }, 2.1, enterDelay, easeOutExpo),
        initial: { x: '-100%' },
      },
    };
  }, []);

  return (
    <LogoWrapper
      onMouseEnter={() => {
        if (day.path !== todayPath) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => requestByPath(todayPath)}
      aria-label={`Make a daily difference ${
        day.path !== todayPath ? 'go to today' : ''
      }`}
    >
      <LogoHeading {...variant(variants.ready, ready)}>
        <svg
          tw="relative block w-[1.5rem] h-[2.375rem] z-10 fill-theme-fg"
          width="24"
          height="38"
          viewBox="0 0 24 38"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.3846 6.10352e-05C19.7944 13.0868 13.0993 29.7506 8.73284 36.9143L0 38.0001C3.43492 28.8103 7.48113 12.6118 9.19859 1.39598L23.3846 6.10352e-05Z" />
        </svg>
        <span tw="block overflow-hidden ml-[-0.9375rem]">
          <motion.span
            css={[TwThemeTransition]}
            tw="block text-theme-base text-[1.125rem] leading-none tracking-[-0.004em] pl-[1.25rem]"
            {...variant(variants.firstLine, ready)}
          >
            Make a Daily
          </motion.span>
          <motion.span
            tw="block text-theme-fg text-[1.375rem] leading-none mt-[-0.125rem] pl-[1.25rem]
                [perspective: 40px] mb-1 // mb prevents clipping on Today"
            {...variant(variants.secondLine, ready)}
          >
            Difference
            {/* 'TODAY' BUTTON POP-OUT (nested for horizontal placement) */}
            <Today
              animate={{
                y: isHovered ? -2 : 0,
                opacity: isHovered ? 1 : 0,
                rotateX: isHovered ? 0 : -90,
              }}
              transition={{
                ease: isHovered ? 'backOut' : 'anticipate',
                duration: 0.3,
              }}
            >
              Today!
            </Today>
          </motion.span>
        </span>
      </LogoHeading>
    </LogoWrapper>
  );
};

const LogoWrapper = tw.button`fixed left-0 top-0 m-0 p-4 z-10 flex-col items-stretch sm:p-8`;
const LogoHeading = tw(motion.h1)`cursor-pointer m-0 flex font-primary z-10`;
const Today = tw(
  motion.span,
)`origin-top hidden cursor-pointer text-center text-theme-base text-[1.375rem] leading-none sm:block`;
