import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useEffect, useState } from 'react';

/**
 * A custom hook which wraps element ResizeObserver registration
 * @param target Ref of element to observe
 * @param handler Handler to invoke when size values change
 * @returns Size DOMRect
 */
export const useElementResize = <T extends HTMLElement>(
  target: RefObject<T>,
  handler: (size: DOMRectReadOnly) => void = () => { },
) => {
  const [size, setSize] = useState<DOMRectReadOnly>();

  useEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry: ResizeObserverEntry) =>
    setSize(entry.contentRect),
  );

  useEffect(() => size && handler(size), [handler, size]);

  return size;
};

export default useElementResize;
