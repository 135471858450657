import { useEffect, useRef } from 'react';
import { isSSG } from '~/config';

export type ViewportData = {
  viewportWidth: number;
  viewportHeight: number;
};

/**
 * A custom hook which wraps window resize event registration.
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * Returns a ref to the viewport dimensions and accepts an optional
 * handler invoked on window resize.
 *
 * @param handler IMPORTANT: Wrap in useCallback. Called on every resize event, as well as once immediately
 * @param deps    List of dependencies used within the handler
 * @returns
 */
export function useResize(
  /* wrap handler in useCallback! */
  handler?: (data: ViewportData) => void,
  deps = [],
) {
  const dimensions = useRef<ViewportData>({
    viewportWidth: 0,
    viewportHeight: 0,
  });

  useEffect(() => {
    if (isSSG) {
      return;
    }

    const handleResize = () => {
      dimensions.current = {
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      };

      if (handler) {
        handler({ ...dimensions.current });
      }
    };

    window.addEventListener('resize', handleResize, false);

    // Fire initial invocation
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, [
    handler,
    // eslint-disable-next-line
    ...deps,
  ]);

  return dimensions;
}
