import { EightBitColor } from '@smockle/contrast';

const MIN_CONTRAST_RATIOS = {
  AAA: {
    regular: 7,
    large: 4.5,
  },
  AA: {
    regular: 4.5,
    large: 3,
  },
} as const;

export type WCAGLevel = keyof typeof MIN_CONTRAST_RATIOS;
/** "large" text is defined as 120-150% larger than body text, or generally >= 16px */
export type WCAGFontSize = keyof typeof MIN_CONTRAST_RATIOS[WCAGLevel];

/**
 * Get a random color
 * @param factor The factor to scale the 0-1 random number with
 * @param offset A floor, added to the above
 * @returns
 */
export const randomColor = (factor: number, offset: number) => {
  // TIL: ~~ = bitwise NOT operator
  // It's used as a more performant alternative to Math.floor()
  // (thanks Kelly!)

  const r = ~~(Math.random() * factor + offset);
  const g = ~~(Math.random() * factor + offset);
  const b = ~~(Math.random() * factor + offset);
  return new EightBitColor(r, g, b);
};

/**
 * Get the WCAG contrast-ratio of two colors. WCAG AA >= 4.5, AAA >= 7.0
 * Taken from `@smockle/contrast` but modified to accept `EightBitColor`s
 * If you want to compare two hex strings, use { Contrast } from that library.
 * @returns
 */
export const contrast = (
  background: EightBitColor,
  foreground: EightBitColor,
) => {
  const F = foreground.luminosity();
  const B = background.luminosity();
  const L1 = Math.max(F, B);
  const L2 = Math.min(F, B);
  const OFFSET = 0.05;
  return (L1 + OFFSET) / (L2 + OFFSET);
};

/**
 * Generate set of random background and foreground colors that meet the given WCAG level and size standards
 * @param level defaults to AAA
 * @param size defaults to regular
 * @param bg a fixed background color to set
 * @returns
 */
export const randomTheme = (
  level: WCAGLevel = 'AAA',
  size: WCAGFontSize = 'regular',
  bg?: EightBitColor,
) => {
  const BACKGROUND_MINIMUM = 215;
  const BACKGROUND_RANGE = 40;
  const FOREGROUND_MINIMUM = 10;
  const FOREGROUND_RANGE = 100;

  let background = bg ?? randomColor(BACKGROUND_RANGE, BACKGROUND_MINIMUM);
  let foreground = randomColor(FOREGROUND_RANGE, FOREGROUND_MINIMUM);
  let i = 0;

  // Attempt up to 10 times to generate a set of [background,foreground] that meets the given standard
  while (
    i++ < 10 &&
    contrast(background, foreground) < MIN_CONTRAST_RATIOS[level][size]
  ) {
    background = bg ?? randomColor(BACKGROUND_RANGE, BACKGROUND_MINIMUM);
    foreground = randomColor(FOREGROUND_RANGE, FOREGROUND_MINIMUM);
  }

  return {
    foreground: `#${foreground.toHexColor().value}`,
    background: `#${background.toHexColor().value}`,
  };
};
