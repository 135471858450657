import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { EmojiInfo, PageContext } from '~/config';

export const useDaysQuery = () => {
  const queryData = useStaticQuery<Queries.GetDayContextDataQuery>(graphql`
    query GetDayContextData {
      allSitePage(
        filter: { path: { regex: "/day/" } }
        sort: { fields: path }
      ) {
        edges {
          node {
            path
            pageContext
          }
        }
      }
      allSanityReactions(sort: { fields: _createdAt, order: DESC }) {
        edges {
          node {
            _id
            emoji
            reaction
            reactionImage {
              asset {
                url
              }
            }
            reactionImageAlt
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const allDayData = queryData.allSitePage.edges.map(
      (d) =>
        // re-casting this to get the page context type in there
        d.node as {
          readonly path: string;
          readonly pageContext: PageContext;
        },
    );
    const dayPaths = allDayData.map((d) => d.path);

    const customReactionId = queryData.allSanityReactions.edges.find(
      ({ node }) => node.reaction === 'Custom',
    )!.node._id;

    const stockReactions = queryData.allSanityReactions.edges
      .filter(({ node }) => node._id !== customReactionId)
      .map(
        ({ node }) =>
          ({
            id: node._id,
            imgSrc: node.reactionImage?.asset?.url,
            imgAlt: node.reactionImageAlt,
            reactionName: node.reaction,
            textEmoji: node.emoji,
          } as EmojiInfo),
      );

    const stockReactionsLookup = stockReactions.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {} as Record<string, EmojiInfo>,
    );

    return {
      /** Data for all days, old to new */
      allDayData,
      /** Day paths */
      dayPaths,
      /** CMS _id of the Reaction model named 'Custom', which should be rendered using the day's custom icon/emoji */
      customReactionId,
      /** The four or so non-custom Reaction CMS models as EmojiInfos */
      stockReactions,
      /** Optimization for mapping incoming reactions efficiently */
      stockReactionsLookup,
    };
  }, [queryData]);
};
