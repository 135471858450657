// -- Path and Hash Utils --

export const stripTrailingSlash = (path?: string) => {
  if (path) {
    // plain trailing slash
    if (path.slice(-1) === '/') {
      return path.replace(/\/$/, '');
    }
    // trailing slash followed by ? or #
    if (/\/[\?#]/.test(path)) {
      return path.split(/\/[\?#]/).shift();
    }
  }
  return path;
};

export const appendSlash = (path?: string) =>
  path ? `${stripTrailingSlash(path)}/` : path;

export const stripHash = (hash?: string) =>
  hash ? hash.replace(/^#/, '') : hash;

export const prependHash = (hash?: string) =>
  hash ? `#${stripHash(hash)}` : hash;

export const getHash = (path?: string) =>
  path?.includes('#') ? `#${path.match(/(^.+)(#.+)(\?.+)?/)?.[1]}` : undefined;

// -- Path Matchers --

export const pathsMatch = (pathA?: string, pathB?: string) =>
  !!pathA && stripTrailingSlash(pathA) === stripTrailingSlash(pathB);

export const hashesMatch = (hashA?: string, hashB?: string) =>
  (!hashA && !hashB) || stripHash(hashA) === stripHash(hashB);

export const pathsMatchWithAnyHash = (
  path?: string,
  currentPath?: string,
  currentHash?: string,
) => !!path && !!currentHash && pathsMatch(path, currentPath);

export const pathsAndHashesMatch = (
  path?: string,
  currentPath?: string,
  hash?: string,
  currentHash?: string,
) => pathsMatch(path, currentPath) && hashesMatch(hash, currentHash);

// -- Generators --

export const makePathWithHash = (path?: string, hash?: string) =>
  path ? appendSlash(path) + (prependHash(hash) ?? '') : path;

/**
 * Normalized pathname with preceding slash and without trailing slash, query or hash.
 *
 * @param pathname a location.pathname
 */
export const getShortPath = (pathname?: string) => {
  let path = pathname?.match(/^\/?[^?#]*/)?.[0];
  if (path) {
    path = path.replace(/\/$/, ''); // strip trailing slash
    if (path && !path?.startsWith('/') && !path?.startsWith('http')) {
      path = `/${path}`; // add preceding slash
    }
  }
  return path;
};

/**
 * Matches page's pathname to a link path, ignoring querystring/hash.
 *
 * @param pathname Page's pathname to match
 * @param to Either a slug or a path string to compare
 */
export const isOnPage = (pathname?: string, to?: string, matchHash = false) => {
  const shortPath = getShortPath(pathname);
  const shortTo = getShortPath(to);
  if (!shortPath && !shortTo) {
    return false; // no information was passed, this is not a valid match!
  }
  return matchHash
    ? pathsAndHashesMatch(shortTo, shortPath, getHash(to), getHash(pathname))
    : pathsMatch(shortPath, shortTo);
};
