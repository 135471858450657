import { useSpring } from 'framer-motion';
import { FC, useCallback, useEffect, useRef } from 'react';
import tw, { css, styled } from 'twin.macro';
import useElementResize from '~/hooks/useElementResize';
import {
  NormalizedWheelEvent,
  useNormalizedMouseWheel,
} from '~/hooks/useNormalizedMouseWheel';
import { usePointer } from '~/hooks/usePointer';
import { useRaf } from '~/hooks/useRaf';
import { clamp, springStep } from '~/utils/math';

type DateSelectorColumnProps = {
  values: string[];
  maxIndex?: number;
  startingIndex?: number;
  onChange?: (index: number) => void;
};

const DRAG_THRESHOLD = 50; // pixels before flagging between a "click" and a "drag"
const DRAG_SPEED_MULT = 2; // increases drag speed so it generally syncs with cursor/finger

/**
 *
 */
export const DateSelectorColumn: FC<DateSelectorColumnProps> = ({
  values,
  maxIndex,
  startingIndex = 0,
  onChange,
}) => {
  const rootRef = useRef<HTMLUListElement>(null);
  const itemRef = useRef<HTMLLIElement>(null);
  const itemRefs = useRef<HTMLLIElement[]>([]);
  const itemSize = useElementResize(itemRef);
  const mouseWheelTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const mouseWheel = useSpring(0, {
    stiffness: 100,
    damping: 10,
  });

  const currentIndex = useRef(0);

  const live = useRef({
    drag: {
      hovering: false,
      active: false,
      prevIsDown: false,
      position: {
        current: 0,
        velocity: 0,
      },
    },
  });

  // Set initial position on open
  useEffect(() => {
    currentIndex.current = startingIndex;
    live.current.drag.position.current = startingIndex;
  }, [startingIndex]);

  // Store item elements for style updates in onTick
  useEffect(() => {
    if (rootRef.current) {
      itemRefs.current = Array.from(rootRef.current.childNodes).map(
        (n) => n as HTMLLIElement,
      );
    }
  }, [rootRef]);

  const handleChange = useCallback(() => {
    onChange && currentIndex.current > -1 && onChange(currentIndex.current);
  }, [onChange]);

  const handleRelease = useCallback(
    (quiet = false) => {
      // Snap to closest index
      currentIndex.current = Math.round(currentIndex.current);
      live.current.drag.active = false;
      if (!quiet) {
        handleChange();
      }
    },
    [handleChange],
  );

  // Handle selection changes due to maxIndex changing
  useEffect(() => {
    handleRelease(true);
  }, [handleRelease, maxIndex]);

  const pointer = usePointer(true);

  const handleMouseEnter = () => {
    live.current.drag.hovering = true;
  };

  const handleMouseLeave = () => {
    live.current.drag.hovering = false;
  };

  const handleItemClick = (index: number) => {
    // Only fire clicks when a drag hasn't been executed
    if (Math.abs(pointer.current.dragY) <= DRAG_THRESHOLD) {
      currentIndex.current = index;
      handleRelease();
    }
  };

  const onTick = ({ elapsed, factor }) => {
    const { isDown, deltaY } = pointer.current;

    // Trigger release
    if (live.current.drag.active && !isDown && live.current.drag.prevIsDown) {
      handleRelease();
    }

    // Initiate drag
    if (live.current.drag.hovering && isDown && !live.current.drag.prevIsDown) {
      live.current.drag.active = true;
    }
    live.current.drag.prevIsDown = isDown;

    if (live.current.drag.active && itemSize) {
      currentIndex.current +=
        -1 * ((deltaY * DRAG_SPEED_MULT) / itemSize.height);
    }

    // Clamp index
    const max = maxIndex || values.length - 1;
    currentIndex.current = clamp(
      currentIndex.current + mouseWheel.get(),
      0,
      max,
    );

    // Update springs
    const springConfig = {
      factor,
      damping: 0.5,
      tension: live.current.drag.active ? 0.15 : 0.1,
    };
    live.current.drag.position = springStep(
      currentIndex.current,
      live.current.drag.position.current,
      live.current.drag.position.velocity,
      springConfig,
    );

    updatePosition(live.current.drag.position.current + mouseWheel.get());
  };

  const updatePosition = (position) => {
    itemRefs.current.forEach((item, i) => {
      // Apply transforms
      item.style.transform = `translateY(${position * -100}%)`;

      // Apply opacity shifts
      const opacity = Math.pow(
        1 - clamp(Math.abs(position - i) * 0.1, 0, 1),
        6,
      );
      const button = item.firstChild as HTMLButtonElement;
      button.style.opacity = `${opacity}`;
    });
  };

  useRaf(true, onTick);

  const handleWheel = useCallback(
    (event: NormalizedWheelEvent) => {
      if (live.current.drag.hovering) {
        const { deltaY } = event;
        mouseWheel.set(-deltaY * 0.0005);

        if (mouseWheelTimeout.current) {
          clearTimeout(mouseWheelTimeout.current);
        }
        mouseWheelTimeout.current = setTimeout(() => {
          mouseWheel.set(0);
          currentIndex.current = Math.round(currentIndex.current);
          handleChange();
        }, 300);
      }
    },
    [handleChange, mouseWheel],
  );

  // CLear timeout on unmount
  useEffect(() => {
    return () => {
      if (mouseWheelTimeout.current) {
        clearTimeout(mouseWheelTimeout.current);
      }
    };
  }, []);

  useNormalizedMouseWheel(handleWheel);

  return (
    <Ul
      ref={rootRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {values.map((v, i) => (
        <li
          ref={itemRef}
          key={v}
          tw="text-[9rem] leading-[0.85] text-theme-fg transition-opacity duration-200"
          css={[maxIndex && i > maxIndex && tw`opacity-15`]}
        >
          <button tw="uppercase" onClick={() => handleItemClick(i)}>
            {v}
          </button>
        </li>
      ))}
    </Ul>
  );
};

const Ul = styled.ul(() => [
  tw`flex flex-col h-full text-right mx-5`,
  css`
    padding: calc(50vh - (9rem / 2));
    padding-left: 0;
    padding-right: 0;
  `,
]);
