// isomorphic - avoid imports (used in gatsby-config & gatsby-node)
export const staticGlobals = {
  shortName: `MADD`,
  defaults: {
    title: `Make a Daily Difference`,
    siteUrl: `https://makeadailydifference.org`,
    author: `y'all`,
    twitterHandle: `@itsyallnyc`,
    linkedInHandle: `@itsyall`,
    description: `National days, observance days, and awareness days, weeks, and months: Find organizations to support on any given day of the year.`,
    keywords: ['interactive calendar', 'holidays', 'yall'],
    defaultLandscapeOgImage: 'https://makeadailydifference.org/madd-og.png',
    // defaultSquareOgImage: 'https://makeadailydifference.org/madd-og-square.png', <- no longer used, see SEO -> twitter:card for note
    ogImageAlt: `Make a Daily Difference, another "for good" project by y'all`,
    icon: 'src/images/favicon.png', // TODO: placeholder
  },
  allowDarkMode: false,
  allowContrastMode: false,
  manifestBgColor: '#F3F2FF',
  manifestThemeColor: '#45413c',
  manifestDisplay: 'browser', // https://developer.mozilla.org/en-US/docs/Web/Manifest/display
  manifestIcon: 'favicon.png',
} as const;

export const defaultHashtags = `#MakeADailyDifference #itsyall`;

export const sanityDataset = process.env.GATSBY_SANITY_DATASET || 'production';

export const isDev =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development';

// process.env.CONTEXT is set by Netlify when hosting there
export const isProduction =
  !isDev &&
  (typeof process.env.CONTEXT === 'undefined' ||
    process.env.CONTEXT === 'production');

export const isSSG = typeof window === 'undefined';

export const isNetlify = isSSG
  ? process.env.NETLIFY === 'true'
  : window.location.hostname.includes('netlify.app');

export enum LocalStorageKey {
  reactionCount = 'com.makeadailydifference.reaction_count',
}
//e.g.: showFuture = 'com.makeadailydifference.show_future',
