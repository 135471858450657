import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import tw from 'twin.macro';
import { DayContext } from '~/context/DayContext';
import { TutorialContext, TutorialStep } from '~/context/TutorialContext';
import assetCalendarIcon from '~/images/calendar-icon.png';
import ExclamationMark from '~/images/exclamation-mark.svg';
import { currentYear, daysInMonth, MONTHS_SHORT, toPath } from '~/utils/date';
import { easeOutQuart, easeOutQuint } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';
import { customTagWithDate, tagDateSlideClick } from '~/utils/tagging';
import { GoButton } from './atoms/GoButton';
import { ButtonClose } from './ButtonClose';
import { DateSelectorColumn } from './DateSelectorColumn';
import { Tooltip } from './Tooltip';

type DateSelectorProps = {
  onButtonEnter: () => void;
  onButtonLeave: () => void;
  onOpen: () => void;
  onClose: () => void;
};

/**
 *
 */
export const DateSelector: FC<DateSelectorProps> = ({
  onButtonEnter,
  onButtonLeave,
  onOpen,
  onClose,
}) => {
  const { setIsReady, day, requestByPath } = useContext(DayContext);
  const tutorial = useContext(TutorialContext);

  const [isOpen, setIsOpen] = useState(false);

  /** startingMonth and startingDay are both zero-based; they're used as indicies */
  const [startingMonth, setStartingMonth] = useState(-1);
  const [startingDay, setStartingDay] = useState(-1);

  /** selectedMonth is also zero-based, but selectedDay is one-based -- fun! */
  const [selectedMonth, setSelectedMonth] = useState(-1);
  const [selectedDay, setSelectedDay] = useState(-1);

  const [selectionExists, setSelectionExists] = useState(false);

  const lastGAEvent = useRef<'month' | 'day'>();

  const handleButtonEnter = () => {
    // Simple dismiss on hover
    if (tutorial.step === TutorialStep.DateSelectorOpen) {
      tutorial.goto(TutorialStep.Idle);
    }
    // Switch from GlobeTrotter to DateSelector tooltip if didn't perform a drag
    else if (tutorial.step === TutorialStep.GlobeTrotterDrag) {
      tutorial.goto(TutorialStep.DateSelectorOpen);
    }

    onButtonEnter();
  };

  const handleButtonLeave = () => {
    // Simple dismiss on hover out
    if (tutorial.step === TutorialStep.DateSelectorOpen) {
      tutorial.goto(TutorialStep.Idle);
    }

    onButtonLeave();
  };

  const open = () => {
    // Set initial position when opened
    const dayDate = dayjs(day.data?.theDate);
    setStartingMonth(dayDate.month());
    setStartingDay(dayDate.date() - 1); // startingDay is zero based, but date() is one-based
    setSelectedMonth(dayDate.month());
    setSelectedDay(dayDate.date()); // selectedDay is one-based (i.e., it is the displayed value)
    setIsReady(false);
    setIsOpen(true);
    onOpen();

    // Dismiss in switched case
    if (tutorial.step === TutorialStep.DateSelectorOpen) {
      tutorial.goto(TutorialStep.Idle);
    }
  };

  const close = useCallback(() => {
    setIsReady(true);
    setIsOpen(false);
    onClose();
  }, [onClose, setIsReady]);

  const handleMonthChange = useCallback((index: number) => {
    setSelectedMonth(index);
    /* GA custom events are gated per 'session' with each column, to relect 
       a distinct use of the column, not every micro-interaction within it */
    if (lastGAEvent.current !== 'month') {
      tagDateSlideClick('month');
      lastGAEvent.current = 'month';
    }
  }, []);

  const handleDayChange = useCallback((index: number) => {
    setSelectedDay(index + 1);
    /* see note in handleMonthChange */
    if (lastGAEvent.current !== 'day') {
      tagDateSlideClick('day');
      lastGAEvent.current = 'day';
    }
  }, []);

  useEffect(() => {
    if (isOpen && selectedMonth > -1 && selectedDay > -1) {
      setSelectionExists(
        requestByPath(
          toPath(new Date(currentYear(), selectedMonth, selectedDay)),
        ),
      );
    }
  }, [requestByPath, selectedMonth, selectedDay, isOpen]);

  const handleKeyup = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    },
    [close],
  );

  // Add key bindings
  useEffect(() => {
    document.addEventListener('keyup', handleKeyup, false);
    () => {
      document.removeEventListener('keyup', handleKeyup, false);
    };
  }, [handleKeyup]);

  const labelShow = {
    opacity: 1,
    y: 0,
  };

  const labelHideBackward = {
    opacity: 0,
    y: 15,
  };

  const labelHideForward = {
    opacity: 0,
    y: -15,
  };

  const hide = day.direction > 0 ? labelHideForward : labelHideBackward;
  const initial = day.direction > 0 ? labelHideBackward : labelHideForward;

  const enterDelay = 0.12;

  const variants = {
    overlay: {
      leave: anim({ opacity: 0 }, 0.4, 0.23, easeOutQuart),
      enter: anim({ opacity: 1 }, 0.4, 0, easeOutQuart),
      initial: { opacity: 0 },
    },
    navMonth: {
      leave: anim({ opacity: 0 }, 0.3, 0.03, easeOutQuart),
      enter: anim({ opacity: 1 }, 0.4, enterDelay + 0.03, easeOutQuart),
      initial: { opacity: 0 },
    },
    navDay: {
      leave: anim({ opacity: 0 }, 0.4, 0.07, easeOutQuart),
      enter: anim({ opacity: 1 }, 0.4, enterDelay, easeOutQuart),
      initial: { opacity: 0 },
    },
    close: {
      leave: anim({ opacity: [1, 0], y: [0, -8] }, 0.2, 0, easeOutQuart),
      enter: anim(
        { opacity: [0, 1, 1, 1], y: [0, 0, -8, 0] },
        1,
        enterDelay + 0.4,
        easeOutQuart,
        [0, 0, 0.33, 1],
      ),
      initial: { opacity: 0, y: 0 },
    },
    labelRoot: {
      leave: anim({ opacity: 0 }, 0.3, 0, easeOutQuart),
      enter: anim({ opacity: 1 }, 0.5, enterDelay + 0.15),
      initial: { opacity: 0 },
    },
    label: {
      leave: anim(hide, 0.15, 0),
      enter: anim(labelShow, 0.8, 0.2, easeOutQuint),
      initial,
    },
  };

  return isMobile || !day.path ? null : (
    <div
      tw="pointer-events-none fixed inset-0 z-10 hidden sm:(block)"
      // remove from screen readers and do not tab to to open button for now
      // the date picker is redundant and dom order made it very difficult for keyboard users to effectively use
      aria-hidden
    >
      <button
        onClick={open}
        onMouseEnter={handleButtonEnter}
        onMouseLeave={handleButtonLeave}
        tw="pointer-events-auto absolute right-0 top-0 p-4 pt-[2.125rem] pr-[2.375rem]"
        css={[`&:hover .icon { opacity: 1 }`]}
        tabIndex={-1}
      >
        <span tw="sr-only">{'Open date selector'}</span>
        <img
          tw="block w-[2.75rem] h-[2.75rem] opacity-0 transition-opacity duration-200 ease-out-quart"
          className="icon"
          src={assetCalendarIcon}
          alt="Date selector icon"
        />
        <span tw="pointer-events-none absolute right-0 top-full mt-1 mr-8">
          <Tooltip visible={tutorial.step === TutorialStep.DateSelectorOpen}>
            {'Click here to jump to a date'}
          </Tooltip>
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div tw="pointer-events-auto fixed inset-0 flex items-end px-6 pl-7 pb-6">
            <motion.div
              tw="absolute inset-0 bg-theme-bg"
              {...variant(variants.overlay)}
            />
            <motion.div
              tw="absolute right-0 top-0 mx-[2.125rem] my-[1.9375rem] z-10"
              {...variant(variants.close)}
            >
              <ButtonClose css={[tw`right-10 top-10`]} onClick={close} />
            </motion.div>
            <motion.div
              tw="absolute right-[10%] inset-y-0 flex flex-col justify-center"
              {...variant(variants.overlay)}
            >
              <GoButton
                disabled={!selectionExists}
                customCss={tw`text-6xl`}
                onClick={() => {
                  customTagWithDate(
                    'go_button',
                    day.data.theDate!,
                    day.data.initiative!,
                  );
                  close();
                }}
              >
                <span tw="flex flex-nowrap items-center gap-2">
                  Go
                  <ExclamationMark tw="height[3rem] fill-theme-base-invert transition duration-200 ease-out-quart" />
                </span>
              </GoButton>
            </motion.div>
            <nav
              tw="absolute inset-0"
              role="dialog"
              aria-modal="true"
              // tabIndex={isOpen ? 0 : -1}
              aria-hidden={isOpen ? 'false' : 'true'}
              aria-describedby="note-taker-dialog"
            >
              <div tw="sr-only" id="note-taker-dialog">
                {`This is a dialog which overlays the main content of the page.
                Pressing the "Close" button or the "Escape" key on your keyboard
                will close the dialog and bring you back to where you were on the
                page.`}
              </div>

              <div tw="absolute inset-0 overflow-hidden select-none flex justify-center">
                <motion.div {...variant(variants.navMonth)}>
                  <DateSelectorColumn
                    values={MONTHS_SHORT}
                    startingIndex={startingMonth}
                    onChange={handleMonthChange}
                  />
                </motion.div>
                <motion.div {...variant(variants.navDay)}>
                  <DateSelectorColumn
                    values={Array.from({ length: 31 }, (_, i) =>
                      `${i + 1}`.padStart(2, '0'),
                    )}
                    maxIndex={daysInMonth(selectedMonth) - 1}
                    startingIndex={startingDay}
                    onChange={handleDayChange}
                  />
                </motion.div>
              </div>
            </nav>
            <motion.div
              tw="absolute left-0 top-1/2 -translate-y-1/2 max-w-[15rem] ml-7"
              {...variant(variants.labelRoot)}
            >
              <AnimatePresence mode="wait">
                <motion.p
                  key={day.data?.initiative}
                  tw="text-2xl text-theme-fg"
                  {...variant(variants.label)}
                >
                  {day.data?.initiative}
                </motion.p>
              </AnimatePresence>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
