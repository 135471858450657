import { useCallback, useEffect } from 'react';
import { RAF_EVENT_NAME, TimeData } from '~/context/RAFContext';

/**
 * A custom hook which wraps requestAnimationFrame registration.
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * @param isActive  Determines if loop should currently be running, to optimize performance this should only be true when necessary
 * @param handler   Invoked on every tick, passed the TimeData object as an argument
 * @param deps      List of dependencies used within the handler
 */
export function useRaf(
  isActive: boolean = true,
  handler: (data: TimeData) => void,
  priority = 1,
  deps = [],
) {
  const handleHandler = useCallback(
    (e: CustomEventInit<TimeData>) => {
      e.detail && e.detail.count % priority === 0 && handler(e.detail);
    },
    [handler, priority],
  );

  useEffect(() => {
    // Don't register for node environments (e.g. Server-Side Rendering)
    if (typeof window === 'undefined') {
      return;
    }

    if (isActive) {
      window.addEventListener(RAF_EVENT_NAME, handleHandler);
    } else {
      window.removeEventListener(RAF_EVENT_NAME, handleHandler);
    }

    return () => {
      window.removeEventListener(RAF_EVENT_NAME, handleHandler);
    };
  }, [
    isActive,
    handleHandler,
    // eslint-disable-next-line
    ...deps,
  ]);
}
