import { AnimatePresence, motion } from 'framer-motion';
import { FC, useRef, useState } from 'react';
import { TwThemeTransition } from '~/context/ThemeContext';
import { useRaf } from '~/hooks/useRaf';
import { easeInOutCubic, easeOutQuart } from '~/utils/easing';
import { clamp } from '~/utils/math';
import { anim, variant } from '~/utils/motion';

export const PRELOAD_DURATION = 2000; // ms
const EXIT_DELAY = 200; // ms, subtracted from PRELOAD_DURATION, time at the end of sequence before dismissing
const STEP_COUNT = 3;

type PreloaderProps = {
  onComplete: () => void;
};

/**
 *
 */
export const Preloader: FC<PreloaderProps> = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const currentTime = useRef(0);
  const sequenceProgress = useRef(0);
  const preloadProgress = useRef(0);

  const rootRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLSpanElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const onTick = ({ delta }) => {
    const SEQUENCE_DURATION = PRELOAD_DURATION - EXIT_DELAY;

    currentTime.current += delta;

    sequenceProgress.current = clamp(
      currentTime.current / SEQUENCE_DURATION,
      0,
      1,
    );
    preloadProgress.current = clamp(
      currentTime.current / PRELOAD_DURATION,
      0,
      1,
    );

    const easedProgress = easeInOutCubic(sequenceProgress.current);

    // Update active step
    setCurrentStep(
      Math.min(
        Math.floor(sequenceProgress.current * STEP_COUNT),
        STEP_COUNT - 1,
      ),
    );

    // Update progress bar
    if (barRef.current) {
      barRef.current.style.transform = `scaleX(${easedProgress}) scaleY(${
        1 - Math.pow(preloadProgress.current, 10)
      })`;
    }

    // Update label
    if (labelRef.current) {
      // labelRef.current.style.transform = `translateY(${
      //   -2 * Math.pow(preloadProgress.current, 100)
      // }%)`;
      labelRef.current.style.opacity = `${
        1 - Math.pow(sequenceProgress.current, 200)
      }`;
    }

    // Quickly fade out upon completion
    if (rootRef.current) {
      rootRef.current.style.opacity = `${
        1 - Math.pow(preloadProgress.current, 30)
      }`;
    }

    // Check completeness
    preloadProgress.current === 1 && onComplete();
  };

  useRaf(true, onTick);

  const variants = {
    step: {
      leave: anim({ opacity: 0, y: 0 }, 0), // instant
      enter: anim(
        { opacity: [0, 1, 1, 1], y: [0, 0, -10, 0] },
        0.45,
        0,
        easeOutQuart,
        [0, 0, 0.5, 1],
      ),
      initial: { opacity: 0, y: 0 },
    },
  };

  return (
    <div
      ref={rootRef}
      tw="fixed inset-0 z-50"
      css={[
        currentStep === 0 && `color: var(--blue); background: var(--blue-wash)`,
        currentStep === 1 && `color: var(--red); background: var(--red-wash)`,
        currentStep === 2 &&
          `color: var(--yellow); background: var(--yellow-wash)`,
        TwThemeTransition,
      ]}
    >
      <div tw="absolute inset-0" />
      <div tw="absolute left-0 w-full h-3">
        <span
          ref={barRef}
          tw="block w-full h-full bg-current origin-top-left"
        ></span>
      </div>
      <div
        ref={labelRef}
        tw="absolute inset-0 flex items-center justify-center text-current text-[16vw] pb-[5vw]"
      >
        <AnimatePresence mode="wait">
          {currentStep === 0 && (
            <motion.span key={0} {...variant(variants.step)}>
              {'Make a'}
            </motion.span>
          )}
          {currentStep === 1 && (
            <motion.span key={1} {...variant(variants.step)}>
              {'daily'}
            </motion.span>
          )}
          {currentStep === 2 && (
            <motion.span key={2} {...variant(variants.step)}>
              {'difference'}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
