import { useEffect, useRef, useState } from 'react';
import { useResize } from './useResize';

export type PointerData = {
  isDown: boolean;
  isTouch: boolean;
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
  normalX: number;
  normalY: number;
  deltaX: number;
  deltaY: number;
  staticDeltaX: number;
  staticDeltaY: number;
  dragX: number;
  dragY: number;
};

export type dragVector = {
  x: number;
  y: number;
};

export const PointerDefaults: PointerData = {
  isDown: false,
  isTouch: false,
  clientX: 0,
  clientY: 0,
  pageX: 0,
  pageY: 0,
  normalX: 0,
  normalY: 0,
  deltaX: 0,
  deltaY: 0,
  staticDeltaX: 0,
  staticDeltaY: 0,
  dragX: 0,
  dragY: 0,
};

/**
 * A custom hook which wraps mouse/touch position event registration
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * A handler can be passed to the hook and is invoked whenever pointer events fire.
 *
 * Also returns reference to the pointer data. The reference can be used to
 * simply fetch the (non-reactive) pointer data without specifying a handler:
 * `const pointer = usePointer(isActive); // Useful in a useRAF() loop`
 *
 * @param isActive        Determines if pointer position should currently be tracked. For performance sake this should only be true when necessary (e.g. while intersecting viewport)
 * @param handler         Invoked on mouse/touch events, current pointerState is passed as an argument
 * @param releaseHandler  Invoked when a drag gesture is released, dragVector passed as an argument
 * @param deps            List of dependencies used within the handler
 * @returns               Reference to the current pointerState, useful if only the data is required
 */
export function usePointer(
  isActive = true,
  handler?: (data: PointerData) => void,
  releaseHandler?: (data: dragVector) => void,
  deps = [],
) {
  const pointerState = useRef({
    ...PointerDefaults,
  } as PointerData);

  const deltaState = useRef({
    prevClientX: 0,
    prevClientY: 0,
    originClientX: 0,
    originClientY: 0,
  });

  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useResize((dimensions) => {
    setViewportWidth(dimensions.viewportWidth);
    setViewportHeight(dimensions.viewportHeight);
  });

  useEffect(() => {
    // Don't register for node environments (e.g. Server-Side Rendering)
    if (typeof window === 'undefined' || viewportWidth === 0) {
      return;
    }

    const propagate = (
      clientX: number,
      clientY: number,
      pageX: number,
      pageY: number,
    ) => {
      pointerState.current.clientX = clientX;
      pointerState.current.clientY = clientY;

      pointerState.current.pageX = pageX;
      pointerState.current.pageY = pageY;

      pointerState.current.normalX = clientX / viewportWidth;
      pointerState.current.normalY = clientY / viewportHeight;

      pointerState.current.deltaX = clientX - deltaState.current.prevClientX;
      pointerState.current.deltaY = clientY - deltaState.current.prevClientY;

      pointerState.current.staticDeltaX =
        clientX - deltaState.current.prevClientX;
      pointerState.current.staticDeltaY =
        clientY - deltaState.current.prevClientY;

      // Reset the delta values each frame so that they can be used in rAF loops
      window.requestAnimationFrame(() => {
        pointerState.current.deltaX = 0;
        pointerState.current.deltaY = 0;
      });

      pointerState.current.dragX = clientX - deltaState.current.originClientX;
      pointerState.current.dragY = clientY - deltaState.current.originClientY;

      if (handler) {
        handler(pointerState.current as PointerData);
      }

      deltaState.current.prevClientX = clientX;
      deltaState.current.prevClientY = clientY;
    };

    const down = (
      clientX: number,
      clientY: number,
      pageX: number,
      pageY: number,
    ) => {
      pointerState.current.isDown = true;
      deltaState.current.originClientX = clientX;
      deltaState.current.originClientY = clientY;

      propagate(clientX, clientY, pageX, pageY);
    };

    const move = (
      clientX: number,
      clientY: number,
      pageX: number,
      pageY: number,
    ) => {
      propagate(clientX, clientY, pageX, pageY);
    };

    const up = (
      clientX: number,
      clientY: number,
      pageX: number,
      pageY: number,
    ) => {
      pointerState.current.isDown = false;

      if (releaseHandler) {
        releaseHandler({
          x: pointerState.current.dragX,
          y: pointerState.current.dragY,
        } as dragVector);
      }

      propagate(clientX, clientY, pageX, pageY);

      deltaState.current.originClientX = 0;
      deltaState.current.originClientY = 0;
    };

    const handleMouseDown = (e: MouseEvent) => {
      down(e.clientX, e.clientY, e.pageX, e.pageY);
    };

    const handleMouseMove = (e: MouseEvent) => {
      move(e.clientX, e.clientY, e.pageX, e.pageY);
    };

    const handleMouseUp = (e: MouseEvent) => {
      up(e.clientX, e.clientY, e.pageX, e.pageY);
    };

    const handleTouchStart = (e: TouchEvent) => {
      pointerState.current.isTouch = true;
      down(
        e.touches[0].clientX,
        e.touches[0].clientY,
        e.touches[0].pageX,
        e.touches[0].pageY,
      );
    };

    const handleTouchMove = (e: TouchEvent) => {
      move(
        e.touches[0].clientX,
        e.touches[0].clientY,
        e.touches[0].pageX,
        e.touches[0].pageY,
      );
    };

    const handleTouchEnd = (e: TouchEvent) => {
      up(
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY,
        e.changedTouches[0].pageX,
        e.changedTouches[0].pageY,
      );
    };

    // const handleMouseLeave = () => {
    //   // When cursor leaves viewport, reset position to center of viewport
    //   up(
    //     viewportviewportWidth / 2,
    //     viewportviewportHeight / 2,
    //   );
    // };

    // Cleanup "down" state on deactivation
    if (!isActive && pointerState.current.isDown) {
      up(
        pointerState.current.clientX,
        pointerState.current.clientY,
        pointerState.current.pageX,
        pointerState.current.pageY,
      );
    }

    if (isActive) {
      document.addEventListener('mousedown', handleMouseDown, false);
      document.addEventListener('mousemove', handleMouseMove, false);
      document.addEventListener('mouseup', handleMouseUp, false);
      document.addEventListener('touchstart', handleTouchStart, false);
      document.addEventListener('touchmove', handleTouchMove, false);
      document.addEventListener('touchend', handleTouchEnd, false);
      // document.addEventListener('mouseleave', handleMouseLeave, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleMouseDown, false);
      document.removeEventListener('mousemove', handleMouseMove, false);
      document.removeEventListener('mouseup', handleMouseUp, false);
      document.removeEventListener('touchstart', handleTouchStart, false);
      document.removeEventListener('touchmove', handleTouchMove, false);
      document.removeEventListener('touchend', handleTouchEnd, false);
      // document.removeEventListener('mouseleave', handleMouseLeave, false);
    };
  }, [
    isActive,
    handler,
    viewportWidth,
    viewportHeight,
    releaseHandler,
    // eslint-disable-next-line
    ...deps,
  ]);

  return pointerState;
}
