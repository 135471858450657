import { motion } from 'framer-motion';
import { FC, useContext } from 'react';
import tw, { theme } from 'twin.macro';
import { DayContext } from '~/context/DayContext';
import { easeOutQuart } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';

type YallCreditProps = {
  //
};

/**
 *
 */
export const YallCredit: FC<YallCreditProps> = (props) => {
  const { ready } = useContext(DayContext);

  const variants = {
    ready: {
      leave: anim({ opacity: 0, y: 0 }, 0), // instant
      enter: anim(
        { opacity: [0, 1, 1, 1], y: [0, 0, -4, 0] },
        0.55,
        1.75,
        easeOutQuart,
        [0, 0, 0.5, 1],
      ),
      initial: { opacity: 0, y: 0 },
    },
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href="https://www.itsyall.com/"
      target="_blank"
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={2} // Using manual tab order for now to avoid moving around exisitng grid/dom order
      tw="w-full px-7 pb-5 sm:(fixed bottom-16 right-0 w-auto pt-10) lg:(bottom-0)"
      css={`
        .out {
          opacity: 1;
        }
        .over {
          opacity: 0;
        }
        @media (min-width: ${theme`screens.sm`}) {
          &:hover {
            .out {
              opacity: 0;
            }
            .over {
              opacity: 1;
            }
          }
        }
        &:hover,
        &:active {
          .badge {
            .bg {
              opacity: 1;
              fill: var(--theme-fg);
            }
            .fg {
              fill: var(--theme-base-invert);
            }
          }
        }
      `}
    >
      <motion.div
        tw="flex items-center justify-center"
        {...variant(variants.ready, ready)}
      >
        <span tw="relative block sm:mb-3.5 font-tertiary text-theme-base text-xs">
          <LabelSegment className="out">
            another <em>“for good”</em> project by
          </LabelSegment>
          <LabelSegment tw="hidden sm:block" className="over">
            check us out,
          </LabelSegment>
        </span>
        <span
          className="badge"
          tw="relative flex items-center justify-center w-12 h-12 ml-1"
        >
          <span tw="sr-only">Ya'll</span>
          <svg
            tw="absolute inset-0 block w-full h-full fill-theme-fg opacity-15 transition duration-200 ease-out-quart"
            className="bg"
            width="47"
            height="47"
            viewBox="0 0 47 47"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M38.3424 6.28723C38.9722 6.73932 39.4442 7.20553 39.8843 7.75439C39.9252 7.79913 39.9674 7.8447 40.0082 7.88945C42.4389 10.5665 44.3613 13.7857 45.1326 17.51C45.3885 18.9763 46.1681 20.1876 46.5044 21.7707C46.513 21.8322 46.5208 21.895 46.5286 21.9578C47.1102 26.5043 46.2823 31.1104 43.4816 35.0024C43.4624 35.0287 43.4432 35.0549 43.4231 35.0826C42.7082 36.0197 41.9497 36.9292 41.1486 37.8004C41.1062 37.8468 41.0639 37.8933 41.0194 37.9403C38.7487 41.5003 35.2525 43.6012 31.4547 44.8741C31.3962 44.8941 31.3363 44.9133 31.2764 44.9325C26.5105 46.5554 21.3764 47.6624 16.5876 45.7673C16.5281 45.751 16.4686 45.7347 16.4085 45.7162C14.5648 45.1854 12.8224 44.3498 11.278 43.1085C11.224 43.0767 11.1713 43.0456 11.1181 43.0124C8.77212 41.601 6.65781 39.8189 5.01038 37.6788C4.9734 37.6307 4.93502 37.5818 4.89886 37.5323C3.44296 35.9636 2.93581 33.9734 2.06933 31.9593C1.90028 31.2959 1.37189 30.6086 1.06428 29.8635C0.228285 28.4311 0.634707 26.7916 0.90257 25.0704C0.954291 24.3493 0.369557 23.4408 0.421278 22.7197C0.432121 21.8335 0.488339 20.959 0.593265 20.1001C0.596978 20.0685 0.600691 20.0369 0.605226 20.0038C0.835043 16.9505 2.81273 14.5463 3.71242 12.0702C6.49369 4.50241 14.6059 2.14467 21.7833 0.739059C23.5495 0.277089 25.3461 0.396823 27.1428 0.516557C31.7577 0.606736 34.8412 3.92796 38.2348 6.24817C38.2721 6.2608 38.3087 6.27484 38.3424 6.28723Z" />
          </svg>
          <svg
            tw="relative fill-theme-fg hover:fill-theme-base-invert"
            className="fg"
            width="32"
            height="19"
            viewBox="0 0 32 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.55596 6.33651C1.69774 5.96255 1.77196 5.75036 2.04791 5.68375C2.35811 5.60858 3.17929 5.68375 3.40005 5.78462C3.63223 5.89119 3.67409 6.0206 3.54659 6.50874C3.41908 6.99688 2.56174 9.83628 2.38285 10.9895C2.27628 11.6794 2.45232 12.2817 2.97281 12.2522C3.59607 12.2161 4.36967 11.8003 4.9739 10.5128C5.86454 8.61545 6.50588 6.44594 6.60008 6.14525C6.71617 5.77415 6.96357 5.69803 7.12914 5.67519C7.42792 5.63427 7.66486 5.72562 7.77048 5.78366C7.8761 5.84171 8.10637 6.00252 7.97411 6.45355C7.84089 6.90458 5.8398 14.476 5.54482 15.3685C5.24985 16.2611 4.94535 17.5342 3.82253 18.3906C2.57221 19.3441 1.16203 19.0634 0.45503 18.2793C-0.194872 17.558 -0.126361 16.4048 0.518784 15.4922C1.24291 14.4674 2.62074 14.26 3.91008 13.8156C5.54482 13.2504 6.1481 13.1809 7.11772 12.5501C8.26909 11.8012 9.0798 10.9134 9.85721 8.94659C10.019 8.53742 10.765 8.69253 10.5261 9.31103C9.6374 11.6176 8.53457 12.6576 7.06158 13.4873C6.12907 14.0126 4.62564 14.6339 3.66838 15.1411C2.67307 15.6683 2.23061 16.2782 2.29912 16.9053C2.35526 17.4153 2.98994 17.4943 3.38958 17.0965C3.84823 16.6407 3.97002 16.2525 4.2631 15.4361C4.63991 14.3856 5.30979 11.7869 5.46394 11.1446C4.96438 12.0125 4.04805 13.1905 2.73492 13.2923C1.68157 13.3741 0.50451 12.8146 0.275189 11.5253C0.0306429 10.1541 1.44749 6.62387 1.55692 6.33651H1.55596Z" />
            <path d="M18.3266 6.77613C18.7215 6.89412 19.3676 7.27949 19.6702 6.92076C19.8672 6.68668 19.5941 6.43262 19.3695 6.25754C19.1449 6.0834 18.0345 5.42303 16.6043 5.54769C15.2722 5.66377 13.8468 6.25754 12.6155 7.94652C11.3385 9.69926 10.9788 11.7451 11.9817 12.9002C12.5746 13.5825 13.8515 13.6244 14.823 13.1191C15.2845 12.8793 15.766 12.3579 16.0677 11.864C16.2218 11.6119 16.3769 11.3359 16.4407 11.2217C16.4369 11.472 16.5777 12.7261 17.5121 13.0687C18.4322 13.4065 19.2658 12.9164 20.0213 12.2684C20.9367 11.4834 21.225 11.2912 21.3782 11.0067C21.5314 10.7222 21.6075 10.5138 21.5276 10.3948C21.4477 10.2759 21.6123 9.75921 21.324 10.0656C21.0842 10.3216 20.7987 10.5909 20.4647 10.8868C18.2286 12.8688 17.7081 11.8098 17.7081 11.236C17.7081 10.2264 18.4142 8.37376 18.4855 8.11209C18.5569 7.85042 18.5417 7.63632 18.339 7.50976C18.1363 7.38321 18.0098 7.33944 17.8309 7.32707C17.652 7.31565 17.3713 7.38702 17.1886 7.86184C17.0059 8.33665 15.9992 11.0257 14.9287 11.8583C13.8582 12.6909 13.3053 11.9906 13.311 11.1561C13.3168 10.2759 14.0152 7.72006 16.2132 6.89983C17.1686 6.543 17.5454 6.543 18.3247 6.77613H18.3266Z" />
            <path d="M31.0155 10.5357C31.1924 10.333 31.3437 10.2445 31.5369 10.4025C31.7186 10.5519 31.6397 10.6879 31.5312 10.8383C31.0345 11.53 30.2304 12.2808 29.669 12.6624C29.041 13.0896 27.9229 13.376 27.0314 13.0592C26.1426 12.7433 25.0969 11.7108 25.6297 8.45467C26.1788 5.09763 27.4358 2.518 28.1589 1.54933C30.1781 -1.1559 32.5141 -0.0635329 31.8671 2.86055C31.3552 5.17851 29.6386 8.58027 26.9115 10.9344C26.0142 11.7089 25.4918 10.9734 26.253 10.4101C28.6956 8.60406 30.6415 4.81692 30.9812 2.9205C31.1725 1.85287 30.357 1.24769 29.4093 2.9205C28.6366 4.28406 27.4348 7.54785 27.1037 10.2122C26.7973 12.6786 28.4349 12.297 28.9573 12.139C29.7461 11.9011 30.6643 10.9353 31.0136 10.5347L31.0155 10.5357Z" />
            <path d="M25.9521 10.6499C26.2195 10.4101 26.169 10.5071 26.3622 10.666C26.5439 10.8154 26.6277 11.0923 26.4878 11.2151C25.816 11.806 24.992 12.4664 24.3335 12.7889C23.6512 13.1229 22.5151 13.3779 21.6235 13.0601C20.7348 12.7442 19.689 11.7118 20.2219 8.45561C20.7709 5.09857 22.0279 2.51894 22.7511 1.55028C24.7703 -1.15496 27.1063 -0.0625869 26.4592 2.8615C25.9473 5.17945 24.2869 8.64877 21.5598 11.0019C20.6625 11.7765 20.1991 11.0409 20.9612 10.4776C23.4038 8.67161 25.2346 4.81787 25.5743 2.92145C25.7656 1.85382 24.9501 1.24864 24.0024 2.92145C23.2297 4.28501 22.0279 7.54879 21.6968 10.2131C21.3904 12.6795 23.0232 12.2808 23.5504 12.14C24.441 11.9021 25.5619 10.9991 25.9521 10.6499Z" />
            <path d="M12.4838 3.81879C12.1317 5.10337 11.4751 6.73907 11.0469 7.44226L10.1906 7.54883C10.5274 6.64677 10.9242 5.05675 11.0926 3.95581L12.4838 3.81784V3.81879Z" />
          </svg>
        </span>
      </motion.div>
    </a>
  );
};

const LabelSegment = tw.span`relative sm:absolute right-0 top-0 whitespace-nowrap transition-opacity duration-200 ease-out-quart pointer-events-none`;
