import { FC } from 'react';
import tw, { styled } from 'twin.macro';
import { SvgCircle } from './svg/SvgCircle';

type ButtonCloseProps = {
  onClick: () => void;
};

/**
 *
 */
export const ButtonClose: FC<ButtonCloseProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <span tw="sr-only">{'Close'}</span>
      <span className="badge">
        <SvgCircle
          tw="absolute inset-0 block w-full h-full fill-theme-fg transition duration-200 ease-out-quart"
          className="bg"
        />
        <svg
          tw="relative fill-theme-base-invert w-6 h-6 transition duration-200 ease-out-quart"
          className="fg"
          viewBox="0 0 20 17.54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20,2.4L12.49,0c-.73,1.16-1.58,2.43-2.49,3.73-.91-1.3-1.76-2.57-2.49-3.73L0,2.4c1.76,2.18,3.87,4.52,6,6.73C3.88,11.85,1.74,14.38,0,16.18l4.67,1.37c1.42-.98,3.29-2.57,5.33-4.47,2.03,1.9,3.91,3.49,5.33,4.47l4.67-1.37c-1.74-1.79-3.88-4.32-6-7.04,2.14-2.21,4.24-4.55,6-6.73Z" />
        </svg>
      </span>
    </Button>
  );
};

const Button = styled.button(() => [
  tw`relative flex items-center justify-center w-[3.125rem] h-[3.125rem]`,
  `
    &:hover {
      .badge {
        .bg {
          fill: black;
        }
        .fg {
          fill: var(--theme-base-invert);
        }
      }
    }
    &:active {
      .badge {
        .bg {
          fill: var(--theme-fg);
        }
        .fg {
          fill: var(--theme-base);
        }
      }
    }
  }`,
]);
