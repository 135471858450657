import { createContext, FC, ReactNode, useState } from 'react';

type TutorialContextProviderProps = {
  children?: ReactNode;
};

export enum TutorialStep {
  Idle,
  GlobeTrotterDrag,
  DateSelectorOpen,
}

export const TutorialContext = createContext({
  step: TutorialStep.Idle,
  goto: (step: TutorialStep) => {},
});

export const TutorialContextProvider: FC<TutorialContextProviderProps> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState(TutorialStep.Idle);

  const goToStep = (step: TutorialStep) => {
    setCurrentStep(
      // Object.values(TUTORIAL_STEPS).includes(step) ? step : TUTORIAL_STEPS.IDLE,
      step,
    );
  };

  return (
    <TutorialContext.Provider
      value={{
        step: currentStep,
        goto: goToStep,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
