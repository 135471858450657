import { HTMLMotionProps, motion } from 'framer-motion';
import { FC, ReactNode, useEffect, useState } from 'react';
import tw from 'twin.macro';
import { CustomCssProps, TwinCSS } from '~/config';
import { isTheme, ThemeChangeEventName } from '~/context/ThemeContext';
import { NineSliceBg } from './NineSliceBg';

type JauntyButtonProps = CustomCssProps &
  HTMLMotionProps<'button'> & {
    ariaHidden?: boolean;
    /** Omit to use theme-fg color */
    bgColor?: string;
    /** Omit to use a default dark gray */
    borderColor?: string;
    children: ReactNode;
    customBgCss?: TwinCSS;
    customContentCss?: TwinCSS;
    ariaLabel?: string;
  };

/**
 *
 */
export const JauntyButton: FC<JauntyButtonProps> = ({
  bgColor,
  borderColor = '#45413c',
  children,
  customCss,
  customBgCss,
  customContentCss,
  ariaLabel,
  ariaHidden = false,
  ...motionButtonProps
}) => {
  const [fg, setFg] = useState<string>('#45413c');

  useEffect(() => {
    // not really sure how to type this; EventListener does not have a generic
    const updateTheme: EventListener = (e) => {
      const detail = e['detail'];
      if (detail && isTheme(detail)) {
        setFg(detail.foreground);
      }
    };

    window.addEventListener(ThemeChangeEventName, updateTheme);
    return () => {
      window.removeEventListener(ThemeChangeEventName, updateTheme);
    };
  }, []);

  return (
    <Button
      className="group"
      css={customCss}
      aria-label={ariaLabel || ''}
      aria-hidden={ariaHidden}
      {...motionButtonProps}
    >
      <NineSliceBg
        bgColor={bgColor ?? fg}
        borderColor={borderColor}
        customCss={[defaultBgCss, customBgCss]}
      />
      <Contents css={customContentCss}>{children}</Contents>
    </Button>
  );
};

const Button = tw(motion.button)`relative
  inline-flex flex-wrap justify-center items-center
  px-7 // no py please, see min-w
  min-h-[52px] // ensure the size of the text container is always at least the size of the outline
  text-theme-base-invert leading-[1.1]
`;

const defaultBgCss = tw`transition-all duration-200 ease-in group-hover:(brightness-125 contrast-125)`;

const Contents = tw.div`z-[1] transition-all duration-200 ease-out-back group-hover:(-translate-y-px)`;
