import { motion, Variants } from 'framer-motion';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import tw, { theme } from 'twin.macro';
import { Emoji } from '~/components/atoms/Emoji';
import { NineSliceBg } from '~/components/atoms/JauntyButton';
import { DayContext } from '~/context';
import { useIsMobile } from '~/hooks';
import { tagEmojiClick } from '~/utils/tagging';
import { REACTION_LIMIT } from './ReactionSelector';

export type ReactionTrayProps = {
  //
};

/**
 * Mobile tray for row of emojis
 */
export const ReactionTray = () => {
  const { day, userReactions } = useContext(DayContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const emojiContainerRef = useRef<HTMLDivElement>(null);
  const [emojiClicked, setEmojiClicked] = useState(null);
  const isMobile = useIsMobile();

  const setButtonDisabled = (state) => {
    if (emojiContainerRef.current) {
      const buttons = emojiContainerRef.current.children;
      for (let i = 0; i < buttons.length; i++) {
        (buttons[i] as HTMLButtonElement).disabled = state;
      }
    }
    if (buttonRef.current) buttonRef.current.disabled = true;
  };

  // Watch date change to toggle button disabled state
  useEffect(() => {
    if (
      day.data &&
      day.data.theDate &&
      userReactions[day.data.theDate] >= REACTION_LIMIT
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [day, userReactions]);

  const buttonVariants = useMemo(
    () =>
      ({
        initial: {
          y: 0,
          transition: {
            duration: 0.35,
          },
        },
        clicked: {
          y: ['0%', '-50%'],
          transition: {
            y: {
              duration: 0.4,
              repeatType: 'reverse',
              repeat: Infinity,
              ease: 'easeOut',
            },
          },
        },
      } as Variants),
    [],
  );

  const handleEmojiClick = useCallback(
    async (e) => {
      if (!day?.data?.theDate) {
        return;
      }

      // Not DRY for just readability
      // Check if user has maxed out reactions
      if (userReactions[day.data.theDate] >= REACTION_LIMIT) {
        return;
      }

      const id = e.currentTarget.id;

      setButtonDisabled(true);
      setEmojiClicked(id);

      await fetch('/api/reaction', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          r: id,
          dayId: day.data._id,
        }),
      });

      // To test click state
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      const event = new CustomEvent('reaction');
      window.dispatchEvent(event);

      setEmojiClicked(null);
    },
    [day, userReactions],
  );
  return (
    <TrayContainer ref={emojiContainerRef}>
      <NineSliceBg
        bgColor={isMobile ? theme`colors.theme-base` : theme`colors.theme-fg`}
        borderColor="#45413C"
        tabIndex={-1}
      />
      {day.selectableReactions?.map((emoji) => (
        <button
          key={`button-${emoji.id}`}
          id={emoji.id}
          onClick={(e) => {
            tagEmojiClick(
              `emoji_${emoji.reactionName.toLowerCase()}`,
              day.data.theDate!,
              day.data.initiative!,
            );
            handleEmojiClick(e);
          }}
          aria-label={`React with ${emoji.imgAlt || emoji.textEmoji} emoji`}
          tw="z-[1] /* required with NineSliceBg - do not remove! */"
        >
          <Emoji
            emoji={emoji}
            customCss={[tw`w-8 h-8`]}
            variants={buttonVariants}
            animate={emojiClicked === emoji.id ? 'clicked' : 'initial'}
          />
        </button>
      ))}
    </TrayContainer>
  );
};

const TrayContainer = tw(
  motion.div,
)`flex flex-row justify-between bg-white py-2 px-4 my-4 mx-6 rounded-full relative z-10 sm:hidden`;
