import { useEffect, useState } from 'react';
import { theme as twTheme } from 'twin.macro';
import { isSSG } from '~/config';

/**
 * A custom hook which wraps window.matchMedia registration
 *
 * @param mediaQuery mq string
 * @param whenKnown Makes hook return `undefined` before js is mounted, for
 *                  deferred render (detect using a strict-equality check)
 * @returns `false` until query matches, or `undefined` with `whenKnown`
 */
export function useMedia(
  mediaQuery: string,
  whenKnown: true,
): undefined | boolean;
export function useMedia(mediaQuery: string): boolean;
export function useMedia(mediaQuery, whenKnown = false) {
  const [value, setValue] = useState(whenKnown ? undefined : false);

  useEffect(() => {
    if (isSSG) {
      return;
    }

    const mediaQueryList = window.matchMedia(mediaQuery);

    const propagate = (e: Partial<MediaQueryListEvent>) => {
      setValue(e.matches);
    };

    // Subscribe
    // Older versions of mobile safari use the deprecated api
    mediaQueryList.hasOwnProperty('addEventListener')
      ? mediaQueryList.addEventListener('change', propagate)
      : mediaQueryList.addListener(propagate);

    // Fire an initial invocation
    // Note that iOS < 14 does not have `MediaQueryListEvent` or `dispatchEvent`
    if (
      typeof window.MediaQueryListEvent !== 'undefined' &&
      typeof mediaQueryList.dispatchEvent !== 'undefined'
    ) {
      mediaQueryList.dispatchEvent(
        new MediaQueryListEvent('change', {
          matches: mediaQueryList.matches,
          media: mediaQuery,
        }),
      );
    } else {
      propagate({
        matches: mediaQueryList.matches,
        media: mediaQuery,
      });
    }

    // Cleanup
    return () =>
      mediaQueryList.hasOwnProperty('removeEventListener')
        ? mediaQueryList.removeEventListener('change', propagate)
        : mediaQueryList.removeListener(propagate);
  }, [mediaQuery]);

  return value;
}

/**
 * For detecting mobile viewport size
 * @returns whether the page is currently less than tailwind's "md"
 */
export function useIsMobile(): boolean;
export function useIsMobile(whenKnown: true): undefined | boolean;
export function useIsMobile(whenKnown?) {
  return useMedia(
    `(max-width: ${
      parseInt(twTheme('screens.md').replace('px', ''), 10) - 1
    }px)`,
    whenKnown,
  );
}

/**
 * For detecting desktop viewport size
 * @returns whether the page is currently less than tailwind's "lg"
 */
export function useIsTablet(): boolean;
export function useIsTablet(whenKnown: true): undefined | boolean;
export function useIsTablet(whenKnown?) {
  return useMedia(
    `(max-width: ${
      parseInt(twTheme('screens.lg').replace('px', ''), 10) - 1
    }px)`,
    whenKnown,
  );
}

/**
 * For detecting dark mode
 * @returns Whether dark-mode is active on user's device
 */
export function useIsDarkModeActive(): boolean;
export function useIsDarkModeActive(whenKnown: true): undefined | boolean;
export function useIsDarkModeActive(whenKnown?) {
  return useMedia('(prefers-color-scheme: dark)', whenKnown);
}

/**
 * For detecting prefers-contrast.
 * @returns Whether user has indicated prefers-contrast
 */
export function usePrefersContrast(): boolean;
export function usePrefersContrast(whenKnown: true): undefined | boolean;
export function usePrefersContrast(whenKnown?) {
  return useMedia('(prefers-contrast: more)', whenKnown);
}

/**
 * For detecting reduced-motion.
 * (Framer Motion has a similar `useReducedMotion` without the `whenKnown` option.)
 * @returns Whether user has indicated prefers-reduced-motion
 */
export function usePrefersReducedMotion(): boolean;
export function usePrefersReducedMotion(whenKnown: true): undefined | boolean;
export function usePrefersReducedMotion(whenKnown?) {
  return useMedia('(prefers-reduced-motion)', whenKnown);
}
