import { keyframes } from '@emotion/react';
import { HTMLMotionProps, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import tw, { css } from 'twin.macro';
import { CustomCssProps } from '~/config';

type GoButtonProps = CustomCssProps &
  HTMLMotionProps<'button'> & {
    children?: ReactNode | string;
    disabled: boolean;
  };

/**
 *
 */
export const GoButton: FC<GoButtonProps> = ({
  customCss,
  children,
  disabled,
  ...props
}) => {
  return (
    <Button
      disabled={disabled}
      className="group"
      css={[
        tw`text-theme-base-invert transition duration-200 ease-out-quart`,
        !disabled &&
          css`
            .svg {
              fill: var(--theme-fg);
            }
            .svg #Layer_1 {
              fill: var(--theme-base-invert);
            }
            &:hover > .svg {
              animation: ${scale} 0.5s ease-in-out;
              fill: black;
            }
          `,
        disabled && tw`opacity-30 cursor-not-allowed`,
        customCss,
      ]}
      {...props}
      title={
        disabled
          ? `Hmm, we don't have anything for that day yet. Please try another!`
          : ''
      }
    >
      <motion.svg
        className="svg bg"
        tw="absolute [z-index: -1] inset-0 w-full h-full transition fill-theme-base duration-200 ease-out-quart group-hover:(fill-theme-fg)"
        transform-origin="center"
        viewBox="0 0 47 47"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path d="M38.3424 6.28723C38.9722 6.73932 39.4442 7.20553 39.8843 7.75439C39.9252 7.79913 39.9674 7.8447 40.0082 7.88945C42.4389 10.5665 44.3613 13.7857 45.1326 17.51C45.3885 18.9763 46.1681 20.1876 46.5044 21.7707C46.513 21.8322 46.5208 21.895 46.5286 21.9578C47.1102 26.5043 46.2823 31.1104 43.4816 35.0024C43.4624 35.0287 43.4432 35.0549 43.4231 35.0826C42.7082 36.0197 41.9497 36.9292 41.1486 37.8004C41.1062 37.8468 41.0639 37.8933 41.0194 37.9403C38.7487 41.5003 35.2525 43.6012 31.4547 44.8741C31.3962 44.8941 31.3363 44.9133 31.2764 44.9325C26.5105 46.5554 21.3764 47.6624 16.5876 45.7673C16.5281 45.751 16.4686 45.7347 16.4085 45.7162C14.5648 45.1854 12.8224 44.3498 11.278 43.1085C11.224 43.0767 11.1713 43.0456 11.1181 43.0124C8.77212 41.601 6.65781 39.8189 5.01038 37.6788C4.9734 37.6307 4.93502 37.5818 4.89886 37.5323C3.44296 35.9636 2.93581 33.9734 2.06933 31.9593C1.90028 31.2959 1.37189 30.6086 1.06428 29.8635C0.228285 28.4311 0.634707 26.7916 0.90257 25.0704C0.954291 24.3493 0.369557 23.4408 0.421278 22.7197C0.432121 21.8335 0.488339 20.959 0.593265 20.1001C0.596978 20.0685 0.600691 20.0369 0.605226 20.0038C0.835043 16.9505 2.81273 14.5463 3.71242 12.0702C6.49369 4.50241 14.6059 2.14467 21.7833 0.739059C23.5495 0.277089 25.3461 0.396823 27.1428 0.516557C31.7577 0.606736 34.8412 3.92796 38.2348 6.24817C38.2721 6.2608 38.3087 6.27484 38.3424 6.28723Z" />
      </motion.svg>
      {children}
    </Button>
  );
};

const Button = tw(
  motion.button,
)`relative z-50 text-white w-40 h-40 flex justify-center items-center`;

const scale = keyframes`
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.1);
  }
  66% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;
