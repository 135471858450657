import { FC } from 'react';

/* Nifty CodePen effect that makes animated buttons seem to stretch goo between them during menu expansion */
export const GooEffectSvg: FC<{ svgId: string }> = ({ svgId }) => (
  <svg id={svgId} xmlns="http://www.w3.org/2000/svg" version="1.1" tw="hidden">
    <defs>
      <filter id={`goo-${svgId}`}>
        <feGaussianBlur
          in="SourceGraphic"
          result={`blur-${svgId}`}
          stdDeviation="10" // careful - 10 is max with 0.9 scale on effect layer, or blur will show when at rest
        />
        <feColorMatrix
          in={`blur-${svgId}`}
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result={`goo-${svgId}`}
        />
        <feComposite
          in2={`goo-${svgId}`}
          in="SourceGraphic"
          result={`mix-${svgId}`}
        />
      </filter>
    </defs>
  </svg>
);
