import { css, Global } from '@emotion/react';
import tw, { GlobalStyles as BaseStyles, theme } from 'twin.macro';
import './root.scss';

/**
 * Sets up global styles -- for :root variables please use root.scss
 */
const customStyles = css`
  html {
    /* Required css for smoothscroll-anchor-polyfill - see util/polyfills.ts */
    scroll-behavior: smooth;
    --scroll-behavior: smooth;
  }

  // Add REM scaling on mobile
  @media (max-width: ${theme`screens.sm`}) {
    html {
      /* 16px @ 375px wide (design size) */
      font-size: ${100 / (375 / 16)}vw;
    }
  }

  body {
    font-family: ${theme`fontFamily.primary`};

    h1 {
      ${tw`text-5xl my-2 font-secondary`}
    }

    h2 {
      ${tw`text-4xl my-1`}
    }

    h3 {
      ${tw`text-3xl my-0.5`}
    }

    p {
      ${tw`text-base`}
    }

    /* Use this setting for layout-intensive sites when font sizes are overridden by iOS */
    /* -webkit-text-size-adjust: none; */
  }

  // current best workaround for body scroll bug
  // see https://github.com/reactjs/react-modal/issues/191
  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .ReactModal__Overlay {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    /* transition: opacity 500ms ease-in-out; */

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Content {
    max-width: 90vw;
    width: 100%;
    height: auto;
  }

  /* Focus visible & polyfill */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Tab focus style */
  button:focus,
  input:focus,
  a:focus {
    outline: gray dashed 1px;
  }
`;

export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);
