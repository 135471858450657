import { FC, MutableRefObject, useContext, useRef } from 'react';
import { TutorialContext, TutorialStep } from '~/context';
import { useIsMobile, useResize } from '~/hooks';
import useElementResize from '~/hooks/useElementResize';
import { usePointer } from '~/hooks/usePointer';
import { useRaf } from '~/hooks/useRaf';
import { clamp, magnitude, pointOnCircle, springStep } from '~/utils/math';
import {
  tagGlobDragEnd as tagGlobeDragEnd,
  tagGlobeDrag,
} from '~/utils/tagging';

type GlobeTrotterDialProps = {
  offsets: MutableRefObject<{ right: number; top: number }>;
  onDragCapture: () => void;
  onDragRelease: () => void;
  onDragStep: (direction: number) => void;
  fade: boolean;
};

// Minimum scale when adjusting based on pointer distance
const MIN_DIST_SCALE = 0.6;

// Scale adjustment when "fade" active (interacting with DateSelector button)
const FADE_SCALE = 0.8;

// Min and max number of clicks of dial within a single interaction, determines the distance between each "step" at the most slow/sensitive gesture level
const MIN_STEPS_PER_DRAG = 10;

// Maximum speed of drag. Steps on the dial are non-linear, and the higher the speed the more steps taken over the gesture.
const MAX_DRAG_THETA = 0.3;
// Theta multiplier applied as drag speed increases (as a proportion of MAX_DRAG_THETA)
const DRAG_SPEED_MULTIPLIER = 8;

// Porportion of PI that handle can be rotated beyond lower right quadrant
const HANDLE_CLAMP_OVERAGE = 0.01;

// Scale of handle when not currently dragging
const HANDLE_MIN_SCALE = 0.2;

// SVG strokeWidth of the ring, applied in rem units
const RING_THICKNESS = 0.025;
const RING_THICKNESS_MOBILE = 0.04;

// Amount of time the "drag to change the date..." tut should be open (in nanoseconds, 4000000 = 4s)
const TUT_DURATION = 7000000;

/**
 *
 */
export const GlobeTrotterDial: FC<GlobeTrotterDialProps> = ({
  offsets,
  onDragCapture,
  onDragRelease,
  onDragStep,
  fade,
}) => {
  const handleContainerRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const ringContainerRef = useRef<HTMLDivElement>(null);
  const ringRef = useRef<SVGSVGElement>(null);
  const ringTutRef = useRef<SVGSVGElement>(null);
  const tutOpenTime = useRef(0);

  const dimensions = useResize();
  const pointer = usePointer(!fade);
  const handleSize = useElementResize(handleContainerRef);
  const ringSize = useElementResize(ringContainerRef);
  const isMobile = useIsMobile();

  const tutorial = useContext(TutorialContext);
  const dragSessionActive = useRef(false);

  // Pointer distance from GlobeTrotter badge before starting to become visible, as a proportion of viewport size (smaller dimension used). Also determines overall scale of the dial (2x range).
  // Values above 0.3 will block the next-month button on iPhone SE, so we shrink that accordingly:
  const VISIBILITY_RANGE = dimensions.current.viewportWidth <= 375 ? 0.3 : 0.35;

  const live = useRef({
    drag: {
      active: false,
      prevIsDown: false,
      prevTheta: 0,
      theta: 0,
      startTheta: 0,
      step: 0,
      prevStep: 0,
    },
    scale: {
      current: 0,
      velocity: 0,
    },
    visibility: {
      current: 0,
      velocity: 0,
    },
    handle: {
      rotation: {
        current: 0,
        velocity: 0,
      },
      scale: {
        current: 0,
        velocity: 0,
      },
    },
    ring: {
      rotation: {
        current: 0,
        velocity: 0,
      },
    },
  });

  const handleDragCapture = () => {
    // console.log('DIAL DRAG CAPTURE!');
    onDragCapture();
  };

  const handleDragRelease = () => {
    if (dragSessionActive.current) {
      dragSessionActive.current = false;
      tagGlobeDragEnd();
    }
    // console.log('DIAL DRAG RELEASE!');
    onDragRelease();
  };

  // Called on each tick
  const handleDragUpdate = () => {};

  // Called each time the dial "clicks" into a new step
  const handleDragStep = (direction: number, isStartDrag: boolean) => {
    if (isStartDrag && !dragSessionActive.current) {
      /* Custom GA events are gated here so they're only sent per drag "session", which accurately represents an instance
      of a user interacting with the dial feature.
      
      Each session starts not on down, but on the first drag-move after down and is capped off on release. */
      dragSessionActive.current = true;
      tagGlobeDrag();
    }

    onDragStep(direction);
  };

  const onTick = ({ elapsed, factor }) => {
    // Only update when refs and sizing is available
    if (
      !(
        ringRef.current &&
        handleRef.current &&
        ringTutRef.current &&
        ringSize &&
        handleSize
      )
    ) {
      return;
    }

    const { viewportWidth, viewportHeight } = dimensions.current;
    const { clientX, clientY } = pointer.current;
    const { right, top } = offsets.current;

    const cX =
      pointer.current.isTouch && !pointer.current.isDown
        ? viewportWidth / 2
        : clientX;
    const cY =
      pointer.current.isTouch && !pointer.current.isDown
        ? viewportHeight / 2
        : clientY;

    const clampedClientX = clamp(cX, 0, viewportWidth);
    const clampedClientY = clamp(cY, 0, viewportHeight);

    const cornerX = viewportWidth;
    const cornerY = 0;

    const originX = cornerX - right;
    const originY = cornerY + top;

    const pointerDist =
      magnitude(clampedClientX - originX, clampedClientY - originY) /
      Math.min(viewportWidth, viewportHeight);

    const pointerTheta = Math.atan2(
      originY - clampedClientY,
      originX - clampedClientX,
    );

    // Clamp theta to be within the lower-left-corner range, so that it's contained within view
    const clampedPointerTheta = clamp(
      Math.PI + pointerTheta,
      Math.PI / 2 - Math.PI * HANDLE_CLAMP_OVERAGE,
      Math.PI + Math.PI * HANDLE_CLAMP_OVERAGE,
    );

    const baseScale =
      clamp(pointerDist / VISIBILITY_RANGE, MIN_DIST_SCALE, 1) *
      (fade ? FADE_SCALE : 1);

    // Initiate drag interaction
    if (
      pointer.current.isDown &&
      !live.current.drag.prevIsDown &&
      pointerDist <= VISIBILITY_RANGE
    ) {
      live.current.drag.active = true;
      live.current.drag.startTheta = live.current.drag.theta; // Reset starting rotation
      live.current.drag.step = live.current.drag.prevStep = 0; // Reset stepping
      handleDragCapture();
    }
    // Release drag
    else if (
      live.current.drag.active &&
      !pointer.current.isDown &&
      live.current.drag.prevIsDown
    ) {
      live.current.drag.active = false;
      handleDragRelease();
    }

    // Apply drag interaction
    if (live.current.drag.active) {
      const thetaDelta = clampedPointerTheta - live.current.drag.prevTheta;

      const dragSpeed = Math.pow(
        Math.min(Math.abs(thetaDelta), MAX_DRAG_THETA) / MAX_DRAG_THETA,
        2, // Apply speed on a curve
      );

      live.current.drag.theta +=
        thetaDelta * (1 + dragSpeed * DRAG_SPEED_MULTIPLIER); // Apply rotation as pointer moves
      handleDragUpdate();

      // Calculate stepping
      const thetaStep = Math.PI / 2 / MIN_STEPS_PER_DRAG;

      const thetaTravel =
        live.current.drag.theta - live.current.drag.startTheta;

      live.current.drag.step = Math.round(thetaTravel / thetaStep);

      // Apply stepping
      if (live.current.drag.step !== live.current.drag.prevStep) {
        const stepDirection =
          live.current.drag.prevStep - live.current.drag.step;

        handleDragStep(stepDirection, live.current.drag.prevStep === 0);
      }
    }
    live.current.drag.prevStep = live.current.drag.step;

    live.current.drag.prevIsDown = pointer.current.isDown;
    live.current.drag.prevTheta = clampedPointerTheta;

    // Update springs
    const springConfig = { factor, damping: 0.5, tension: 0.07 };

    live.current.scale = springStep(
      baseScale,
      live.current.scale.current,
      live.current.scale.velocity,
      springConfig,
    );
    live.current.visibility = springStep(
      // Hide if "fade" active (interacting with DateSelector button), and show only when within pointer range
      !fade && (pointerDist <= VISIBILITY_RANGE || live.current.drag.active)
        ? 1
        : 0,
      live.current.visibility.current,
      live.current.visibility.velocity,
      springConfig,
    );
    live.current.handle.rotation = springStep(
      clampedPointerTheta,
      live.current.handle.rotation.current,
      live.current.handle.rotation.velocity,
      springConfig,
    );
    live.current.handle.scale = springStep(
      live.current.drag.active ? 1 : 0,
      live.current.handle.scale.current,
      live.current.handle.scale.velocity,
      springConfig,
    );
    live.current.ring.rotation = springStep(
      live.current.drag.theta,
      live.current.ring.rotation.current,
      live.current.ring.rotation.velocity,
      springConfig,
    );

    // Calculate position for handle
    const pointOnRing = pointOnCircle(
      live.current.handle.rotation.current,
      (ringSize.width / 2) * live.current.scale.current * 0.995, // Multiply by magic number to improve alignment, due to the ring SVG being slightly inset from edges
      originX,
      originY,
    );

    const THICKNESS =
      viewportWidth < 640 ? RING_THICKNESS_MOBILE : RING_THICKNESS;

    const ringThickness =
      THICKNESS *
      live.current.visibility.current *
      (1 / live.current.scale.current);

    const handleScale =
      (HANDLE_MIN_SCALE +
        (1 - HANDLE_MIN_SCALE) * live.current.handle.scale.current) *
      Math.pow(live.current.visibility.current, 2);

    const ringOpacity = live.current.visibility.current;

    // Apply to elements
    handleRef.current.style.transform = `translate(${pointOnRing[0]}px, ${pointOnRing[1]}px) scale(${handleScale})`;
    ringRef.current.style.transform = `rotate(${live.current.ring.rotation.current}rad) scale(${live.current.scale.current})`;
    ringRef.current.style.strokeWidth = `${ringThickness}rem`;
    ringRef.current.style.opacity = `${ringOpacity}`;

    // Apply to tutorial label
    if (!isMobile && tutorial.step === TutorialStep.GlobeTrotterDrag) {
      if (
        // We want the user to see the tut for at least TUT_DURATION
        tutOpenTime.current < TUT_DURATION
      ) {
        // ...but only while it's mostly visible
        if (live.current.visibility.current > 0.5) {
          tutOpenTime.current += elapsed;
        }
        ringTutRef.current.style.opacity = `${ringOpacity}`;
      } else if (tutOpenTime.current >= TUT_DURATION) {
        // They've got the message, we're done
        tutorial.goto(TutorialStep.Idle);
      }

      // On "laptop" (let's say <=1536w) put the label outside of the circle so it's easier to read
      ringTutRef.current.style.transform = `scale(${
        viewportWidth <= 1536
          ? live.current.scale.current + 0.22
          : live.current.scale.current
      })`;
    } else {
      ringTutRef.current.style.opacity = `0`;
    }
  };

  useRaf(true, onTick);

  return (
    <>
      <div
        ref={handleContainerRef}
        tw="pointer-events-none fixed left-0 top-0 w-10 h-10 sm:w-14 sm:h-14 -ml-5 -mt-5 sm:-ml-7 sm:-mt-7"
      >
        <span
          ref={handleRef}
          tw="absolute inset-0 bg-theme-fg rounded-full"
        ></span>
      </div>
      <div
        ref={ringContainerRef}
        tw="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
        css={{
          width: VISIBILITY_RANGE * 2 * 100 + 'vw',
          maxWidth: VISIBILITY_RANGE * 2 * 100 + 'vh',
          height: VISIBILITY_RANGE * 2 * 100 + 'vw',
          maxHeight: VISIBILITY_RANGE * 2 * 100 + 'vh',
        }}
      >
        <svg
          ref={ringRef}
          tw="pointer-events-none absolute inset-0 stroke-theme-fg"
          css={{
            /* Stroke adjusted in onTick */
            strokeWidth: `${RING_THICKNESS}rem`,
          }}
          viewBox="0 0 99.36 99.62"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <path d="M89.85,78.32c.32-.45,.38-.8,1-1.31l.68-1.37c6.33-9.58,8.35-21.5,6.88-32.71-.43-2.5-1-5.79-1.87-8.22-.4-1.47-.97-3.3-1.31-4.38-.46-1.5-1.51-3.25-2.33-4.64-1.85-3.1-3.66-6.4-6.46-8.74-.73-1.53-2.21-2.36-3.23-3.62-.24-.31-.52-.14-.87-.36h0c-6.76-6.21-16.3-11.01-25.7-11.74-.63-.65-1.41-.26-2.1-.31-3.14-.61-6.54-.52-9.76-.05-1,.23-2.1-.19-3.09,.37-2.04-.13-3.84,1.05-5.86,1.05-2.26,1.19-5.44,1.43-7.32,3.09-4.2,1.08-7.64,4.34-11.08,6.91-.73,.63-1.29,1.5-2.08,2.02-.81,.52-1.03,1.66-2.1,1.84-3.65,4.52-7.08,9.25-9.29,14.68l-.32,1.73c-.1,.19-.24,.16-.36,.26-.57,.52-.31,1.24-.5,1.86-.76,2.1-1.38,4.32-1.55,6.59-.61,.68-.1,1.57-.37,2.25-.63,2.43-.2,4.97-.31,7.46-.14,7.11,1.82,14.1,4.78,20.55,6.85,13.37,19.9,24.44,34.89,26.48,.89,.12,1.36,.08,2,.55,.58,.42,1.44,.19,2.13,.11,3.23,.51,6.66,.64,9.95,.05,.78-.19,1.6,.02,2.42-.31,.78-.29,1.74,.08,2.57-.4,4.17-.12,7.95-2.23,11.74-3.75,.52-.16,1.15-.29,1.42-.65,.44-.58,1.1-.42,1.6-.78,1.44-1.05,3.09-1.78,4.35-3.09,1.63-.52,2.58-1.99,3.91-2.92,1.15-1.16,2.73-2.02,3.65-3.39,1.12-1.48,2.27-2.87,3.52-4.22l.36-.9Z" />
        </svg>
      </div>
      <svg
        ref={ringTutRef}
        tw="absolute right-0 top-0 opacity-0 origin-top-right fill-theme-fg transition-opacity"
        css={{
          width: VISIBILITY_RANGE * 1 * 100 + 'vw',
          maxWidth: VISIBILITY_RANGE * 1 * 100 + 'vh',
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 399 411"
      >
        <g>
          <path d="M297.4,401.7l-11.5-9.9l-1.1,6c-12.8-2.7-25.6-6-38.1-10l-0.9,2.9c12.6,4,25.5,7.4,38.4,10.1l-1.1,6L297.4,401.7z" />
          <path d="M42,227.8c-10.7-17.2-20-35.3-27.7-53.9l5.6-2.2L8,162.2l-2.2,15.1l5.7-2.2c7.8,18.8,17.2,37.1,27.9,54.4L42,227.8z" />
        </g>
        <g>
          <path
            d="M42.7,238.6c-0.1-1,0.1-1.9,0.5-2.7c0.4-0.9,1-1.6,1.9-2.1c0.9-0.6,1.8-0.9,2.7-0.9s1.8,0.2,2.7,0.6c0.9,0.5,1.6,1.1,2.2,2
		c0.5,0.7,0.8,1.5,0.9,2.3c0.1,0.8,0.1,1.5-0.1,2.3l-2-0.5c0.3-1,0.1-2-0.4-2.8c-0.4-0.5-0.8-0.9-1.3-1.2s-1.1-0.4-1.6-0.3
		c-0.6,0-1.1,0.2-1.7,0.6c-0.5,0.4-0.9,0.8-1.2,1.3s-0.4,1.1-0.3,1.6c0,0.6,0.2,1.1,0.6,1.7c0.6,0.9,1.4,1.4,2.5,1.5l-0.3,2.1
		c-0.8-0.1-1.5-0.3-2.2-0.8c-0.7-0.4-1.2-1-1.7-1.8C43.1,240.4,42.8,239.5,42.7,238.6z"
          />
          <path d="M56,239.7l1.3,1.9l-9,6.2l-1.3-1.9L56,239.7z" />
          <path
            d="M56,244.9l1.3,1.9l-6.5,4.6l-1.3-1.9L56,244.9z M57.3,244.2c0-0.4,0.2-0.6,0.5-0.9c0.3-0.2,0.6-0.3,1-0.2s0.7,0.3,0.9,0.6
		s0.3,0.7,0.3,1c0,0.4-0.2,0.6-0.5,0.8s-0.6,0.3-1,0.2s-0.7-0.3-0.9-0.6C57.3,244.9,57.2,244.5,57.3,244.2z"
          />
          <path
            d="M53.3,254.1c-0.1-0.8,0-1.5,0.2-2.1s0.7-1.2,1.4-1.7c0.6-0.5,1.3-0.7,2-0.8s1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.8,1.8,1.5
		s0.8,1.4,0.9,2.1c0.1,0.7,0,1.4-0.4,2l-1.8-0.9c0.3-0.8,0.3-1.5-0.2-2.1c-0.4-0.5-0.8-0.8-1.4-0.8c-0.6-0.1-1.1,0.1-1.6,0.5
		s-0.9,0.9-0.9,1.4s0.1,1.1,0.4,1.5c0.5,0.6,1.1,0.9,1.9,0.8l0.3,2c-0.7,0.1-1.3,0.1-2-0.2s-1.2-0.8-1.7-1.4
		C53.8,255.6,53.4,254.9,53.3,254.1z"
          />
          <path
            d="M61.7,260.8l-1.5-0.2l-1.6,1.2l-1.4-1.8l8.7-6.7l1.4,1.8l-4.9,3.8l4.6,0.7l1.7,2.2l-4.7-0.6l-1.4,5.6l-1.7-2.2L61.7,260.8z
		"
          />
          <path
            d="M74.7,270.5c0,0.8-0.5,1.5-1.3,2.2l-3.6,3l-0.9-1l0.8-0.7c-0.4,0.1-0.9,0-1.3-0.2c-0.4-0.2-0.8-0.6-1.2-1
		c-0.6-0.7-0.8-1.3-0.9-2c0-0.7,0.2-1.2,0.8-1.7s1.1-0.6,1.8-0.5s1.3,0.6,2,1.4l1.4,1.7l0.2-0.2c0.5-0.4,0.7-0.8,0.7-1.3
		s-0.2-1-0.6-1.5c-0.3-0.4-0.6-0.7-1-0.9s-0.8-0.4-1.2-0.5l0.4-1.1c0.5,0.1,1,0.3,1.5,0.6c0.5,0.3,0.9,0.7,1.3,1.2
		C74.4,268.9,74.7,269.7,74.7,270.5z M69.4,273.1c0.4,0.1,0.9,0.1,1.3,0l0.8-0.7l-1.4-1.7c-0.8-0.9-1.5-1.1-2.1-0.6
		c-0.3,0.3-0.4,0.6-0.4,0.9c0,0.4,0.2,0.8,0.5,1.2C68.5,272.7,68.9,272.9,69.4,273.1z"
          />
          <path
            d="M81,278c0,0.8-0.5,1.6-1.3,2.3l-3.4,3l-0.9-1.1l3.3-2.9c0.6-0.5,0.9-1,0.9-1.5s-0.1-1-0.6-1.6s-1.1-0.9-1.7-0.9
		c-0.6,0-1.3,0.2-1.9,0.8l-3,2.6l-0.9-1.1l5.9-5.1l0.9,1l-0.9,0.8c0.5-0.1,1,0,1.5,0.3s0.9,0.6,1.3,1C80.8,276.4,81.1,277.2,81,278z
		"
          />
          <path
            d="M91.1,283.2l-8.1,7.4l-0.9-1l0.9-0.8c-0.5,0.1-1,0-1.5-0.2s-0.9-0.5-1.3-0.9c-0.5-0.6-0.8-1.2-1-1.8
		c-0.2-0.7-0.1-1.3,0.1-2c0.2-0.7,0.6-1.3,1.2-1.8c0.6-0.5,1.2-0.9,1.9-1c0.7-0.2,1.3-0.1,2,0.1s1.2,0.6,1.7,1.2
		c0.4,0.4,0.7,0.9,0.8,1.4c0.2,0.5,0.2,1,0.1,1.5l3.2-2.9L91.1,283.2z M82.3,287.6c0.4,0.1,0.9,0.2,1.4,0s0.9-0.4,1.3-0.7
		c0.4-0.4,0.7-0.8,0.8-1.2s0.2-0.9,0.1-1.3c-0.1-0.5-0.3-0.9-0.7-1.2c-0.3-0.4-0.7-0.6-1.2-0.8c-0.4-0.1-0.9-0.2-1.4,0
		c-0.5,0.1-0.9,0.4-1.3,0.7s-0.7,0.8-0.8,1.2s-0.2,0.9-0.1,1.4c0.1,0.5,0.3,0.9,0.7,1.2C81.5,287.2,81.8,287.5,82.3,287.6z"
          />
          <path
            d="M94.8,288l3.3,3.3c0.8,0.8,1.3,1.6,1.6,2.6c0.3,0.9,0.3,1.8,0.1,2.7c-0.2,0.9-0.7,1.7-1.4,2.4c-0.7,0.7-1.5,1.2-2.4,1.4
		c-0.9,0.2-1.8,0.2-2.7-0.1s-1.8-0.9-2.6-1.7l-3.3-3.3L94.8,288z M92.1,297.2c0.7,0.7,1.5,1.1,2.4,1.1s1.6-0.3,2.3-1s1-1.5,1-2.3
		s-0.4-1.6-1.1-2.4l-1.5-1.5l-4.5,4.5L92.1,297.2z"
          />
          <path
            d="M103.7,300.4c0.5,0.2,0.9,0.5,1.4,0.9l-1.5,1.5c-0.2-0.2-0.3-0.3-0.4-0.4c-0.5-0.5-1-0.7-1.5-0.7s-1,0.3-1.6,0.8l-2.6,2.7
		l-1.7-1.6l5.5-5.7l1.6,1.5l-0.7,0.8C102.7,300.2,103.2,300.2,103.7,300.4z"
          />
          <path
            d="M109.7,306.8c0.1,0.9-0.3,1.7-1.1,2.6l-3.1,3.3l-1.6-1.5l0.7-0.7c-0.8,0.2-1.7,0-2.5-0.8c-0.4-0.4-0.8-0.8-1-1.3
		s-0.2-0.9-0.2-1.3c0.1-0.4,0.3-0.8,0.6-1.2c0.5-0.5,1.1-0.8,1.8-0.7c0.7,0.1,1.5,0.5,2.3,1.3l1.3,1.2c0.3-0.4,0.5-0.8,0.4-1.2
		c0-0.4-0.3-0.8-0.7-1.2c-0.3-0.3-0.7-0.5-1.1-0.7s-0.8-0.3-1.1-0.3l0.5-1.7c0.5,0.1,1.1,0.2,1.6,0.5s1.1,0.6,1.5,1.1
		C109.1,305.1,109.6,306,109.7,306.8z M104.5,309.5c0.3,0.1,0.7,0,1-0.2l0.5-0.6l-1.2-1.1c-0.7-0.6-1.3-0.7-1.7-0.3
		c-0.2,0.2-0.3,0.5-0.2,0.8s0.2,0.6,0.5,0.8C103.9,309.3,104.2,309.5,104.5,309.5z"
          />
          <path
            d="M118.5,313.6l-4.4,5c-1,1.1-2,1.7-3,1.7s-2.1-0.4-3.2-1.4c-0.6-0.5-1.1-1.1-1.5-1.6c-0.4-0.6-0.6-1.2-0.8-1.7l1.8-0.6
		c0.1,0.4,0.2,0.9,0.5,1.3c0.3,0.5,0.6,0.9,1,1.2c0.6,0.5,1.2,0.8,1.7,0.8s1-0.3,1.5-0.9l0.2-0.3c-0.9,0.1-1.7-0.2-2.5-0.9
		c-0.5-0.5-0.9-1-1.2-1.7c-0.2-0.6-0.3-1.3-0.2-2s0.4-1.3,0.9-1.9s1.1-1,1.7-1.2c0.6-0.2,1.3-0.2,2-0.1c0.7,0.2,1.3,0.5,1.8,1
		c0.9,0.8,1.3,1.6,1.2,2.6l0.7-0.8L118.5,313.6z M113,315.6c0.5,0,1-0.2,1.4-0.7s0.5-0.9,0.5-1.4c-0.1-0.5-0.3-1-0.8-1.4
		s-1-0.6-1.5-0.6s-1,0.2-1.4,0.7c-0.4,0.4-0.5,0.9-0.5,1.4c0.1,0.5,0.3,1,0.8,1.4S112.5,315.6,113,315.6z"
          />
          <path
            d="M121.8,326.1c-0.3,0-0.6-0.1-0.9-0.2s-0.6-0.3-0.8-0.5c-0.6-0.5-0.9-1-1-1.7s0.2-1.2,0.7-1.8l2.7-3.3l-1-0.8l0.7-0.9l1,0.8
		l1.1-1.3l1.1,0.9l-1.1,1.3l1.7,1.4l-0.7,0.9l-1.7-1.4l-2.7,3.3c-0.3,0.3-0.4,0.6-0.4,1c0,0.3,0.2,0.6,0.5,0.8
		c0.3,0.3,0.7,0.4,1.1,0.4L121.8,326.1z"
          />
          <path
            d="M123.8,327.8c-0.3-0.7-0.4-1.3-0.3-2c0.1-0.7,0.4-1.3,0.9-1.9s1-1,1.7-1.3c0.6-0.3,1.3-0.3,2-0.2c0.7,0.1,1.3,0.4,2,0.9
		c0.6,0.5,1.1,1,1.3,1.7c0.3,0.6,0.4,1.3,0.3,2s-0.4,1.3-0.9,1.9s-1,1-1.7,1.3s-1.3,0.3-2,0.2s-1.3-0.4-2-0.9
		S124.1,328.4,123.8,327.8z M127.2,329c0.5,0.1,0.9,0,1.3-0.2c0.4-0.2,0.8-0.5,1.2-0.9c0.3-0.4,0.5-0.9,0.6-1.3
		c0.1-0.5,0-0.9-0.1-1.3c-0.2-0.4-0.5-0.8-0.8-1.1s-0.8-0.5-1.3-0.6s-0.9,0-1.3,0.2c-0.4,0.2-0.8,0.5-1.2,0.9
		c-0.3,0.4-0.5,0.9-0.6,1.3c-0.1,0.5,0,0.9,0.1,1.3c0.2,0.4,0.5,0.8,0.8,1.1C126.3,328.8,126.8,329,127.2,329z"
          />
          <path
            d="M134.4,335.8c-0.3-0.6-0.4-1.3-0.3-2s0.3-1.4,0.8-2s1-1.1,1.6-1.3c0.6-0.3,1.3-0.4,2-0.3s1.4,0.4,2,0.9
		c0.6,0.4,1,0.9,1.3,1.5s0.4,1.1,0.3,1.8l-1.3-0.1c0-0.4-0.1-0.9-0.2-1.2s-0.5-0.7-0.8-0.9c-0.4-0.3-0.8-0.5-1.3-0.5
		c-0.5-0.1-0.9,0-1.3,0.2s-0.8,0.5-1.1,1c-0.3,0.4-0.5,0.9-0.6,1.4s0,0.9,0.2,1.3s0.5,0.8,0.9,1.1s0.7,0.4,1.1,0.5s0.8,0,1.2-0.1
		l0.5,1.2c-0.6,0.3-1.2,0.3-1.8,0.2c-0.6-0.1-1.2-0.4-1.8-0.8C135.1,337.1,134.7,336.5,134.4,335.8z"
          />
          <path
            d="M149.1,338.3c0.1,0.8-0.1,1.7-0.7,2.6l-2.6,3.7l-1.2-0.8l2.5-3.6c0.4-0.6,0.6-1.2,0.5-1.7s-0.4-1-1-1.4s-1.3-0.6-1.9-0.5
		s-1.2,0.5-1.6,1.2l-2.3,3.3l-1.2-0.8l6.3-9l1.2,0.8l-2.4,3.5c0.5-0.1,0.9-0.2,1.4,0c0.5,0.1,1,0.3,1.5,0.7
		C148.5,336.8,149,337.5,149.1,338.3z"
          />
          <path
            d="M156.3,343c0.2,0.8,0,1.6-0.6,2.5l-2.6,3.9l-1.1-0.7l0.6-0.9c-0.4,0.2-0.8,0.2-1.3,0.1s-1-0.3-1.5-0.6
		c-0.7-0.5-1.2-1-1.4-1.7s-0.1-1.3,0.3-1.8c0.4-0.6,0.9-0.9,1.5-1s1.4,0.2,2.3,0.8l1.9,1.2l0.2-0.2c0.3-0.5,0.4-1,0.3-1.5
		s-0.5-0.9-1-1.3c-0.4-0.3-0.8-0.4-1.3-0.6c-0.5-0.1-0.9-0.2-1.3-0.1l0.1-1.2c0.5,0,1.1,0,1.6,0.2s1.1,0.4,1.6,0.8
		C155.6,341.6,156.1,342.3,156.3,343z M151.9,347c0.5,0,0.9-0.1,1.3-0.4l0.6-0.9l-1.8-1.2c-1-0.7-1.7-0.7-2.2,0
		c-0.2,0.3-0.3,0.7-0.1,1c0.1,0.4,0.4,0.7,0.9,1C151,346.9,151.5,347,151.9,347z"
          />
          <path
            d="M164.5,348.4c0.2,0.8,0,1.7-0.6,2.6l-2.4,3.8l-1.2-0.8l2.3-3.7c0.4-0.6,0.6-1.2,0.5-1.7s-0.4-1-1-1.3
		c-0.6-0.4-1.3-0.5-1.9-0.4s-1.1,0.6-1.6,1.3l-2.1,3.4l-1.2-0.8l4.2-6.6l1.1,0.7l-0.6,1c0.5-0.2,0.9-0.2,1.5-0.2
		c0.5,0.1,1.1,0.3,1.6,0.6C163.8,346.9,164.3,347.6,164.5,348.4z"
          />
          <path
            d="M174.1,353.2l-3.4,5.8c-0.7,1.2-1.5,1.9-2.4,2.1s-2,0-3.1-0.7c-0.6-0.4-1.2-0.8-1.7-1.3s-0.8-1-1-1.6l1.1-0.6
		c0.2,0.5,0.4,0.9,0.8,1.3s0.8,0.8,1.3,1.1c0.8,0.5,1.5,0.6,2.1,0.5s1.1-0.6,1.6-1.4l0.3-0.5c-0.5,0.2-1,0.2-1.5,0.2s-1-0.3-1.5-0.5
		c-0.6-0.4-1.1-0.9-1.5-1.4c-0.4-0.6-0.5-1.2-0.5-1.9s0.2-1.3,0.6-2c0.4-0.6,0.9-1.1,1.4-1.4c0.6-0.3,1.2-0.5,1.9-0.4s1.3,0.2,2,0.6
		c0.5,0.3,0.9,0.7,1.2,1.1s0.5,0.9,0.6,1.5l0.6-1L174.1,353.2z M168.8,357c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,1-1c0.4-0.7,0.5-1.3,0.3-2
		s-0.6-1.2-1.3-1.6c-0.7-0.4-1.4-0.5-2-0.4c-0.7,0.1-1.2,0.5-1.6,1.2c-0.3,0.4-0.4,0.9-0.4,1.3s0.1,0.9,0.4,1.3c0.2,0.4,0.6,0.7,1,1
		C167.9,356.8,168.3,356.9,168.8,357z"
          />
          <path
            d="M180.3,361.7l-5.5-3.1c-0.3,0.6-0.3,1.3,0,1.9s0.7,1.1,1.4,1.5c0.8,0.5,1.6,0.5,2.4,0.3l0.2,1.2c-0.5,0.2-1,0.2-1.6,0.2
		c-0.6-0.1-1.1-0.3-1.7-0.6c-0.7-0.4-1.3-0.9-1.7-1.5c-0.4-0.6-0.6-1.3-0.6-2s0.2-1.4,0.6-2.1s0.9-1.2,1.4-1.5
		c0.6-0.4,1.2-0.6,1.9-0.6c0.7,0,1.4,0.2,2,0.5s1.2,0.8,1.5,1.4s0.5,1.2,0.5,1.9s-0.2,1.4-0.6,2.1
		C180.4,361.4,180.4,361.6,180.3,361.7z M176.7,356.7c-0.6,0.1-1.1,0.5-1.5,1l4.4,2.5c0.3-0.6,0.3-1.2,0.1-1.8
		c-0.2-0.6-0.6-1-1.2-1.4C177.9,356.7,177.3,356.6,176.7,356.7z"
          />
          <path
            d="M187.9,369.1c-0.3,0.1-0.6,0.1-0.9,0s-0.6-0.2-0.9-0.3c-0.7-0.4-1.1-0.8-1.3-1.4s-0.1-1.2,0.3-1.9l2-3.8l-1.2-0.6l0.5-1
		l1.2,0.6l0.8-1.5l1.3,0.7l-0.8,1.5l2,1l-0.5,1l-2-1l-2,3.8c-0.2,0.4-0.3,0.7-0.2,1c0.1,0.3,0.3,0.5,0.6,0.7
		c0.4,0.2,0.8,0.3,1.2,0.2L187.9,369.1z"
          />
          <path
            d="M198.1,366.9c0.3,0.8,0.2,1.7-0.3,2.7l-2,4l-1.3-0.6l1.9-3.9c0.3-0.7,0.4-1.3,0.3-1.8s-0.5-0.9-1.1-1.2
		c-0.7-0.3-1.3-0.4-1.9-0.2s-1.1,0.7-1.4,1.4l-1.8,3.6l-1.3-0.6l4.9-9.8l1.3,0.6l-1.9,3.8c0.4-0.2,0.9-0.3,1.4-0.3s1,0.2,1.6,0.4
		C197.3,365.5,197.9,366.1,198.1,366.9z"
          />
          <path
            d="M206,374.5l-5.8-2.7c-0.2,0.7-0.2,1.3,0.1,1.9c0.3,0.6,0.8,1.1,1.5,1.4c0.8,0.4,1.7,0.4,2.4,0.1l0.3,1.2
		c-0.5,0.2-1,0.3-1.6,0.3s-1.1-0.2-1.7-0.4c-0.7-0.3-1.3-0.8-1.8-1.4c-0.4-0.6-0.7-1.2-0.7-1.9c-0.1-0.7,0.1-1.4,0.4-2.1
		s0.8-1.2,1.3-1.6s1.2-0.6,1.9-0.7c0.7-0.1,1.4,0.1,2,0.4c0.7,0.3,1.2,0.7,1.6,1.3s0.6,1.2,0.6,1.9s-0.1,1.4-0.4,2.1
		C206.2,374.3,206.1,374.4,206,374.5z M202.1,369.8c-0.6,0.2-1.1,0.6-1.4,1.1l4.5,2.1c0.2-0.6,0.2-1.2,0-1.8s-0.7-1-1.3-1.3
		C203.3,369.7,202.7,369.6,202.1,369.8z"
          />
          <path
            d="M221,372.8l-4.3,10.1l-1.2-0.5l0.5-1.1c-0.5,0.3-1,0.4-1.5,0.4s-1.1-0.1-1.6-0.3c-0.7-0.3-1.3-0.7-1.7-1.3
		c-0.4-0.5-0.7-1.2-0.8-1.8c-0.1-0.7,0-1.4,0.3-2.1s0.7-1.3,1.3-1.7c0.5-0.4,1.2-0.7,1.8-0.7c0.7-0.1,1.4,0,2.1,0.3
		c0.5,0.2,1,0.5,1.3,0.9c0.4,0.4,0.6,0.8,0.7,1.3l1.7-4L221,372.8z M214.8,380.5c0.5-0.1,0.9-0.2,1.2-0.5c0.4-0.3,0.7-0.7,0.9-1.2
		s0.3-1,0.2-1.5s-0.2-0.9-0.5-1.3s-0.7-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.2s-0.9,0.2-1.2,0.5c-0.4,0.3-0.7,0.7-0.9,1.2
		c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.5,0.2,0.9,0.5,1.3s0.6,0.6,1.1,0.8S214.4,380.5,214.8,380.5z"
          />
          <path
            d="M226.7,379.2c0.3,0.7,0.3,1.5-0.1,2.5l-1.7,4.4l-1.2-0.5l0.4-1c-0.4,0.3-0.8,0.4-1.2,0.4s-1-0.1-1.6-0.3
		c-0.8-0.3-1.4-0.8-1.7-1.4s-0.4-1.2-0.1-1.8s0.7-1.1,1.3-1.3s1.4-0.1,2.5,0.3l2.1,0.8l0.1-0.3c0.2-0.6,0.2-1.1,0-1.5
		s-0.6-0.8-1.3-1c-0.4-0.2-0.9-0.3-1.3-0.3c-0.5,0-0.9,0-1.3,0.2l-0.2-1.2c0.5-0.2,1-0.2,1.6-0.2s1.2,0.2,1.8,0.4
		C225.7,378,226.4,378.5,226.7,379.2z M223.3,384c0.5-0.1,0.8-0.3,1.2-0.6l0.4-1l-2-0.8c-1.1-0.4-1.8-0.3-2.1,0.5
		c-0.1,0.4-0.1,0.7,0.1,1s0.6,0.6,1.1,0.8C222.4,384.1,222.8,384.1,223.3,384z"
          />
          <path
            d="M231.6,388.1c-0.3,0.1-0.5,0.1-0.9,0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.7-0.3-1.2-0.7-1.5-1.2s-0.3-1.2,0-1.9l1.5-4l-1.2-0.5
		l0.4-1.1l1.2,0.5l0.6-1.6l1.3,0.5l-0.6,1.6l2.1,0.8l-0.4,1.1l-2.1-0.8l-1.5,4c-0.1,0.4-0.2,0.7,0,1c0.1,0.3,0.4,0.5,0.7,0.6
		c0.4,0.2,0.8,0.2,1.2,0L231.6,388.1z"
          />
          <path
            d="M240.5,388.1l-6-2.1c-0.1,0.7,0,1.3,0.3,1.9c0.4,0.6,0.9,1,1.6,1.2c0.9,0.3,1.7,0.3,2.4-0.1l0.4,1.1
		c-0.5,0.3-1,0.4-1.6,0.5c-0.6,0-1.2-0.1-1.8-0.3c-0.8-0.3-1.4-0.7-1.9-1.2s-0.8-1.1-0.9-1.8s-0.1-1.4,0.2-2.1
		c0.2-0.7,0.6-1.3,1.2-1.8c0.5-0.5,1.1-0.8,1.8-0.9c0.7-0.1,1.4-0.1,2.1,0.2c0.7,0.2,1.3,0.6,1.7,1.1s0.7,1.1,0.8,1.8s0,1.4-0.2,2.1
		C240.7,387.8,240.6,388,240.5,388.1z M236.2,383.8c-0.6,0.2-1,0.7-1.3,1.3l4.7,1.7c0.1-0.6,0.1-1.2-0.2-1.8s-0.8-0.9-1.4-1.2
		C237.3,383.5,236.7,383.6,236.2,383.8z"
          />
        </g>
      </svg>
    </>
  );
};
