// Example GA Tagging
import { isDev, isSSG } from '~/config';

const confirmDataLayerAndPushEventTag = (eventTag) => {
  // @ts-ignore -- dataLayer DOES exist, it's added by `gatsby-plugin-google-tagmanager`
  if (!isSSG && window.dataLayer) {
    if (isDev) {
      // NOTE: log is for debugging purposes, filter the console output to `event:` for minimal additional noise while testing.
      console.info(eventTag);
    } else {
      // @ts-ignore -- dataLayer DOES exist, it's added by `gatsby-plugin-google-tagmanager`
      window.dataLayer.push(eventTag);
    }
  } else {
    console.error(
      'Error: `dataLayer` not available on the `window` object. Confirm that settings are enabled for this environment.',
    );
  }
};

export const tagPreviewedResource = (header: string) => {
  // single-file preview available from mobile and any size browser
  confirmDataLayerAndPushEventTag({ event: `Previewed ${header}` });
};

export const tagDateArrowClick = (
  direction: 'back' | 'forward',
  arrowType: 'month' | 'day',
) => {
  confirmDataLayerAndPushEventTag({
    event: `datenav_${arrowType}_${direction}`,
  });
};

export const tagGlobeDrag = () => {
  confirmDataLayerAndPushEventTag({ event: `globe_dragging` });
};

export const tagGlobDragEnd = () => {
  confirmDataLayerAndPushEventTag({ event: `globe_drag_finished` });
};

export const tagDateSlideClick = (type: 'month' | 'day') => {
  confirmDataLayerAndPushEventTag({ event: `${type}_slide_change` });
};

export const tagEmojiClick = (
  type: string,
  date: string,
  initiative: string,
) => {
  confirmDataLayerAndPushEventTag({ event: `${type}`, date, initiative });
};

export const customTagWithDate = (
  button: string,
  date: string,
  initiative: string,
) => {
  confirmDataLayerAndPushEventTag({
    event: `clicked_${button}`,
    date,
    initiative,
  });
};
