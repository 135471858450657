import { motion, MotionProps } from 'framer-motion';
import { FC } from 'react';
import tw from 'twin.macro';
import { CustomCssProps, EmojiInfo } from '~/config';

export type EmojiProps = CustomCssProps &
  MotionProps & {
    emoji: EmojiInfo;
  };

/**
 *
 */
export const Emoji: FC<EmojiProps> = ({ emoji, customCss, ...motionProps }) =>
  emoji.imgSrc ? (
    <motion.img
      tw="block m-0 w-6 h-6 sm:(w-8 h-8)"
      src={emoji.imgSrc}
      alt={emoji.imgAlt || ''}
      css={customCss}
      {...motionProps}
    />
  ) : (
    <motion.p css={[tw`block m-0 text-2xl`, customCss]} {...motionProps}>
      {emoji.textEmoji}
    </motion.p>
  );
